import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { Utils } from './utils';
import { TypeOperation } from '../domain/typeOperation';


@Injectable()
export class TypeOperationService extends AbstractService<TypeOperation>{
  table = "typeOperation";
  libelle ="Type operation";
  
  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
    this.getEntities();
  }

  getHttp() {
    return this.http;
  }
  getUtils(){
    return this.utils;
  }
}