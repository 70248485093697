import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Categorie, Produit } from '../domain/produit';
import { Nature } from '../domain/Nature';
import { ProduitService } from '../service/produitService';
import { CategorieService } from '../service/categorieService';
import { NatureService } from '../service/natureService';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';

@Component({
    templateUrl: './produit.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class ProduitComponent implements OnInit, OnDestroy {

    produitDialog: boolean;
    produits: Produit[];
    produit: Produit;
   
    categorieDialog: boolean;
    categories: Categorie[];
    categorie: Categorie;

    natures: Nature[];


    submitted: boolean;
    isDisabled: boolean;

    subscriptionProduit: Subscription;
    subscriptionNature: Subscription;
    subscriptionCategorie: Subscription;
    
    constructor(private produitService: ProduitService, 
                private categorieService: CategorieService,
                private natureService: NatureService,
                private messageService: MessageService,
                private utils: Utils,
                private confirmationService: ConfirmationService) {}

    ngOnInit() {
        this.natures = [];
        this.categories = [];
        // this.produit = {typeId:0, categorieProduitId:0};
        this.subscriptionNature = this.natureService.entitiesSubject
            .subscribe((natures: Nature[]) => {
                if(natures){
                    for(let nature of natures){
                        if(!nature.isAcademicien){
                            this.natures.push(nature);
                        }
                    }
                }
            });

        this.subscriptionCategorie = this.categorieService.entitiesSubject
            .subscribe((categories: Categorie[]) => {
                this.categories = categories;
                // if(this.categories)
                //     this.categories.splice(0, 0, {id:'0',libelle:'Tous'});
            });

        this.subscriptionProduit = this.produitService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.produitService.action === "create"){
                        // if(this.produits){
                        //     this.produits.splice(0, 0, response.items[0]); // inserer en tete de liste
                        // }
                        // else {
                        //     this.produits = [response.items[0]];
                        // }
                        // ajout du produit au datasProduit de categorie
                        for(let categorie of this.categories){
                            if(categorie.id == this.produit.categorieProduitId){
                                if(categorie.datasProduit){
                                    categorie.datasProduit.splice(0, 0, response.items[0]);
                                }
                                else {
                                    categorie.datasProduit = [response.items[0]];
                                }
                            }
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du produit effectuée avec success', life: 3000});
                        this.produitDialog = false;
                        this.produit = {};
                    }

                    if(this.produitService.action === "update"){
                        // let index = this.utils.findIndexById(this.produits, response.items[0].id).index;
                        // console.log('... apres le premier findIndexById ... ', index);
                        // this.produits[index] = response.items[0];   
                        for(let categorie of this.categories){
                            if(categorie.id == this.produit.categorieProduitId){
                                if(categorie.datasProduit){
                                    let index = this.utils.findIndexById(categorie.datasProduit, response.items[0].id).index;
                                    console.log('... apres le second findIndexById ... ', index);
                                    categorie.datasProduit[index] = response.items[0];  
                                }
                                else {
                                    categorie.datasProduit = [response.items[0]];
                                }
                               
                            }
                        }
                        this.messageService.add({severity:'success', summary: 'Mise à jour du produit effectuée avec success', life: 3000});
                    }

                    if(this.produitService.action === "delete"){// a revoir 
                        // let index = this.utils.findIndexById(this.produits, this.produit.id).index;
                        // this.produits.splice(index,1);
                        for(let categorie of this.categories){
                            if(categorie.id == this.produit.categorieProduitId){
                                let index = this.utils.findIndexById(categorie.datasProduit, this.produit.id).index;
                                categorie.datasProduit.splice(index,1);   
                            }
                        }
                        this.produit = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de produit effectuée avec success', life: 3000});
                    }
                    // this.produitService.entities = [...this.produits];
                }
            });

            this.subscriptionCategorie = this.categorieService.responseSubject
                .subscribe((response: _Response) => {
                    this.submitted = false;
                    this.isDisabled = false;
                    if(response.hasError){
                        this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                    }else {
                        if(this.categorieService.action === "create"){
                            if(this.categories){
                                this.categories.splice(0, 0, response.items[0]); // inserer en tete de liste
                            }
                            else {
                                this.categories = [response.items[0]];
                            }
                            this.messageService.add({severity:'success', summary: 'Creation du categorie effectuée avec success', life: 3000});
                            this.categorieDialog = false;
                            this.categorie = {};
                        }
                        if(this.categorieService.action === "update"){
                            let index = this.utils.findIndexById(this.categories, response.items[0].id).index;
                            this.categories[index] = response.items[0];    
                            this.messageService.add({severity:'success', summary: 'Mise à jour du categorie effectuée avec success', life: 3000});
                        }
                        if(this.categorieService.action === "delete"){
                            let index = this.utils.findIndexById(this.categories, this.categorie.id).index;
                            this.categories.splice(index,1);
                            this.categorie = {};
                            this.messageService.add({severity:'success', summary: 'Suppression de categorie effectuée avec success', life: 3000});
                        }

                        this.categorieService.entities = [...this.categories];
                    }
                });

          this.natureService.emitEntities();
          this.categorieService.emitEntities();
    }

    filtrerProduit(){
        console.log('filtrage de produit : ', this.produit);
    }

    calculateTotalScan(produits: Produit[]) {
        let total = 0;
        if (produits) {
            for (let produit of produits) {
                if(produit.nbreScan){
                    total = total+produit.nbreScan;
                }
            }
        }
        return total;
    }

    hideDialog() {
        this.categorieDialog = false;
        this.produitDialog = false;
        this.submitted = false;
        this.isDisabled = false;
    }
    
    openNewProduitDialog() {
        this.produit = {categorieProduitId: this.categories[0].id};
        this.submitted = false;
        this.isDisabled = false;
        this.produitDialog = true;
    }

    openEditProduitDialog(produit: Produit) {
        this.produit = {...produit};
        if(this.categories.length == 1){
            this.produit.categorieProduitId = this.categories[0].id;
        }
     //   this.beforeOpenProduitDialog();
        console.log('le produit : ', this.produit);

        this.produitDialog = true;
    }

    deleteProduit(produit: Produit) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + produit.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            key: 'produit',
            accept: () => {
                this.produitService._request = {};
                this.produitService._request.datas = [produit];
                this.produit = produit;
                this.produitService.action = "delete";
                this.produitService.makeRequest();
            }
        });
    }
   
    saveProduit() {
        this.submitted = true;
        this.isDisabled = true;
        if (this.produit.libelle && this.produit.libelle.trim() && this.produit.prixQrcode) {
            if (this.produit.id) {
                this.produitService.action = "update";
                // supprimer le produit de la liste de categorie dans laqulle il se trouve
            }
            else {
                this.produitService.action = "create";
            }
            this.produitService._request = {};
            this.produitService._request.datas = [this.produit];
    
           this.produitService.makeRequest(); 
        } 
        else {
            this.isDisabled = false;
        }  
    }

    openNewCategorieDialog() {
        this.categorie = {typeId: this.natures[0].id};
        this.submitted = false;
        this.isDisabled = false;
        this.categorieDialog = true;
    }
    
    openEditCategorieDialog(categorie: Categorie) {
        this.categorie = {...categorie};
        if(this.natures.length == 1){
            this.categorie.typeId = this.natures[0].id;
        }
        this.categorieDialog = true;
    }
    
    deleteCategorie(categorie: Categorie) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + categorie.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            key: 'categorie',
            accept: () => {
                this.categorieService._request = {};
                this.categorieService._request.datas = [categorie];
                this.categorie = categorie;
                this.categorieService.action = "delete";
                this.categorieService.makeRequest();
            }
        });
    }
    
    saveCategorie() {
        this.submitted = true;
        this.isDisabled = true;
        if (this.categorie.libelle && this.categorie.libelle) {
            if (this.categorie.id) {
                this.categorieService.action = "update";
            }
            else {
                this.categorieService.action = "create";
            }
            this.categorieService._request = {};
            this.categorieService._request.datas = [this.categorie];
        
            this.categorieService.makeRequest();    
        }
        else{
            this.isDisabled = false;
        }
    }


    ngOnDestroy(): void {
        this.subscriptionNature.unsubscribe();
        this.subscriptionCategorie.unsubscribe();
        this.subscriptionProduit.unsubscribe();
    }
   
}
