import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { Operation } from '../domain/operation';
import { OperationService } from '../service/operationService';
import { TypeOperationService } from '../service/typeOperationService';
import { TypeOperation } from '../domain/typeOperation';
import { Nature } from '../domain/Nature';
import { Etat } from '../domain/etat';
import { Pays } from '../domain/academicien';
import { PaysService } from '../service/PaysService';
import { EtatService } from '../service/etatService';
import { NatureService } from '../service/natureService';
import { AcademicienService } from '../service/academicienService';
import { StaticMessage } from '../utilities/staticMessage';
import { Produit } from '../domain/produit';
import { ProduitService } from '../service/produitService';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    templateUrl: './operation.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class OperationComponent implements OnInit, OnDestroy {

    operationDialog: boolean;
    operations: Operation[];
    operation: Operation;
   
    typeOperations: TypeOperation[];
    produits: Produit[];
    etats: Etat[];
    natures: Nature[];
    paysList: Pays[];
    
    submitted: boolean;
    isDisabled: boolean;
    operationSearchField: string;
    cadeauDialog: boolean;

    totalRecord: number;
    tabViewActiveIndex: number;
    isLoadOpeartion: boolean;
    fieldFilter: any;
    text: string;
    subscriptionOperation: Subscription;
    subscriptionTypeOperation: Subscription;
    subscriptionProduit: Subscription;
    subscriptionEtat: Subscription;
    subscriptionNature: Subscription;
    subscriptionPays: Subscription;
    subscriptionAcademicien: Subscription;

    constructor(private operationService: OperationService, 
                private messageService: MessageService,
                private typeOperationService: TypeOperationService,
                private produitService: ProduitService,
                private academicienService: AcademicienService,
                private natureService: NatureService,
                private etatService: EtatService,
                private paysService: PaysService,
                private utils: Utils,
                private cdr: ChangeDetectorRef,
                private confirmationService: ConfirmationService) {}

    ngOnInit() {
       this.operations = [];
        this.typeOperations = [];
        this.tabViewActiveIndex = 0;
        this.fieldFilter = {
            dateDebut:null, 
            dateFin:null, 
            academicienMatricule:null,
            typeOperationLibelle:null,
            natureId:null,
            etatCode:null,
            produitId: null
        };
        this.fieldFilter.dateDebut = new Date(new Date().setDate(new Date().getDate()-30));
        this.fieldFilter.dateFin = new Date();

        this.isDisabled = true;
        this.subscriptionTypeOperation = this.typeOperationService.entitiesSubject
        .subscribe((typeOperations: TypeOperation[]) => {
            if(typeOperations){
                this.typeOperations = typeOperations;
                this.typeOperations.splice(0, 0,  {code: null, libelle: '-'});
            }
        });
    
        this.subscriptionProduit = this.produitService.entitiesSubject
        .subscribe((produits: Produit[]) => {
            if(produits){
                this.produits = produits;
                this.produits.splice(0, 0,  {id: null, libelle: '-'});
            }
        });

        this.subscriptionPays = this.paysService.entitiesSubject
        .subscribe((paysList: Pays[]) => {
            if(paysList){
                this.paysList = paysList;
                this.fieldFilter.paysId = 1;
            }
        });
        
        this.subscriptionNature = this.natureService.entitiesSubject
        .subscribe((natures: Nature[]) => {
            if(natures){
                var natureTempon: Nature[] = [];
                for(let nature of natures){
                    if(!nature.isAcademicien){
                        natureTempon.push(nature);
                    }
                }
                this.natures = natureTempon;
                this.natures.splice(0, 0,  {id:null, libelle: '-'});
            }
        });

        this.subscriptionEtat = this.etatService.entitiesSubject
        .subscribe((etats: Etat[]) => {
            if(etats){
                var etatTempon: Etat[] = [];
                for(let etat of etats){
                    if(etat.type == StaticMessage.TYPE_OPERATION){
                        etat.libelle = etat.libelle;
                        etatTempon.push( etat);
                    }
                }
                this.etats = etatTempon;
                this.etats.splice(0, 0,  {id:null, libelle: '-'});
            }
        });


        this.typeOperationService.emitEntities();
        this.produitService.emitEntities();
        this.etatService.emitEntities();
        this.natureService.emitEntities();
        this.paysService.emitEntities();

        this.subscriptionOperation = this.operationService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Error', detail:response.status.message, life: 3000});
                }else {
                    if(this.operationService.action === "getByCriteria"){
                        // this.operation = {...response.items[0]}
                        if(response.items){
                            this.operations = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.totalRecord = 0;
                        this.operations = [];
                    }

                    if(this.operationService.action === "create"){ // un cadeau 
                        if(this.operations){
                            for(let prod of response.items){
                                this.operations.splice(0, 0, prod); // inserer en tete de liste
                            }
                        }
                        else {
                            this.operations = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du operation effectuée avec success', life: 3000});
                        this.operationDialog = false;
                        this.operation = {};
                    }

                    if(this.operationService.action === "update"){// validation retirer de la liste
                        let index = this.utils.findIndexById(this.operations, response.items[0].id).index;
                        this.operations.splice(index,1);  
                        this.messageService.add({severity:'success', summary: 'Mise à jour du operation effectuée avec success', life: 3000});
                    }

                    if(this.operationService.action === "generateExcelFile"){
                        this.operationService.action = "downloadExcelFile?fileName=details_des_operations.xlsx";
                        this.operationService.getMethod();
                    }
                }
            });

        this.subscriptionAcademicien = this.academicienService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Error', detail:response.status.message, life: 3000});
                }else {
                    if(this.academicienService.action === "kdoSippec"){ // un cadeau 
                        if(this.operations){
                            for(let prod of response.items){
                                this.operations.splice(0, 0, prod); // inserer en tete de liste
                            }
                        }
                        else {
                            this.operations = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du operation effectuée avec success', life: 3000});
                        this.operationDialog = false;
                        this.operation = {};
                    }
                }
            });
    }


    loadOperation(event){
        setTimeout(() => {
            this.operations = [];
            this.isLoadOpeartion = true;
            this.criteriaSearchOperation(event.rows, event.first/event.rows, "getByCriteria");
       }, 1000);
      

    }
       
    criteriaSearchOperation(size:string, index:number, method: string){
        this.isDisabled = true;
        let newDateDebut = null;
        let newDateFin = null;
        
        if(this.fieldFilter.dateDebut && this.fieldFilter.dateFin){
            newDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
            newDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();
        }

        this.operationService._request = {};
        this.operationService._request.data = {
            createdAt:newDateDebut, 
            createdAtParam: {"operator":"[]",start:newDateDebut, end:newDateFin},
            academicienMatricule:this.fieldFilter.matricule,
            typeOperationCode:this.fieldFilter.typeOperationCode,
            // typeId:this.fieldFilter.natureId,
            paysId:this.fieldFilter.paysId,
            etatCode:this.fieldFilter.etatCode,
        };

        if(this.tabViewActiveIndex == 0){
            this.operationService._request.data.etatCode = this.fieldFilter.etatCode;
            this.operationService._request.data.typeOperationCode = this.fieldFilter.typeOperationCode;
            this.operationService._request.data.produitId = this.fieldFilter.produitId;
        }
        if(this.tabViewActiveIndex == 1){
            if(this.isLoadOpeartion){
                this.fieldFilter.dateDebut = null;
                this.fieldFilter.dateFin = null;
                this.operationService._request.data.createdAt = null;
                this.operationService._request.data.createdAtParam = null;
            }
            this.isLoadOpeartion = false;
            this.operationService._request.data.etatCode = StaticMessage.ETAT_DEMANDE;
            this.operationService._request.data.typeOperationCode = StaticMessage.TYPE_OPERATION_TRANSACTION;

        }
        if(this.tabViewActiveIndex == 2){
            this.operationService._request.data.typeOperationCode = StaticMessage.TYPE_OPERATION_CADEAU;
        }


        if(!this.fieldFilter.paysId){
            this.operationService._request.data.paysId = 1;
        }

        this.operationService._request.size = size;
        this.operationService._request.index = index;

        this.operationService.action = method;
        this.operationService.makeRequest();
    }

    hideDialog() {
        this.operationDialog = false;
        this.submitted = false;
        this.isDisabled = false;
    }
    
    validerTransaction(operation:Operation){
        this.isDisabled =true;
        this.operationService.action = "update";
        operation.etatCode = StaticMessage.ETAT_SUCCES; 
        operation.etatType = StaticMessage.TYPE_OPERATION; 
        this.operationService._request = {};
        this.operationService._request.datas = [operation];
        this.operationService.makeRequest(); 
    }

    annulerTransaction(operation:Operation){
        this.isDisabled =true;

        this.operationService.action = "update";
        operation.etatCode = StaticMessage.ETAT_ECHEC; 
        operation.etatType = StaticMessage.TYPE_OPERATION; 
        this.operationService._request = {};
        this.operationService._request.datas = [operation];
        this.operationService.makeRequest(); 
    }

    openNewCadeauDialog() {
        this.operation = {}
        this.submitted = false;
        this.isDisabled = false;
        this.cadeauDialog = true;
    }

    // handleChangeTabView(){
    //     this.criteriaSearchOperation('5',0);
    // }  
    
    envoyerCadeau() {
        this.submitted = true;
        this.isDisabled = true;

        if (this.operation.academicienMatricule && this.operation.montant) {
            this.academicienService.action = "kdoSippec";
            this.academicienService._request = {};
            this.academicienService._request.datas = [{matricule:this.operation.academicienMatricule, montantKdo:this.operation.montant}];
            this.academicienService.makeRequest(); 
        } 
        else {
            this.isDisabled = false;
        }  
    } 

    ngOnDestroy(): void {
        this.subscriptionTypeOperation.unsubscribe();
        this.subscriptionEtat.unsubscribe();
        this.subscriptionNature.unsubscribe();
        this.subscriptionPays.unsubscribe();
        this.subscriptionAcademicien.unsubscribe();
        this.subscriptionOperation.unsubscribe();
        this.subscriptionProduit.unsubscribe();
        this.typeOperations.splice(0,1);
        this.produits.splice(0,1);
    }
   
}
