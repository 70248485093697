import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Groupe, Niveau, OperateurMobile, Pays } from '../domain/academicien';
import { Contrainte } from '../domain/Contrainte';
import { Nature } from '../domain/Nature';
import { _Response } from '../domain/_response';
import { ContrainteService } from '../service/contrainteService';
import { GroupeService } from '../service/groupeService';
import { NatureService } from '../service/natureService';
import { NiveauService } from '../service/niveauService';
import { OperateurMobileService } from '../service/operateurMobileService';
import { PaysService } from '../service/PaysService';
import { Utils } from '../service/utils';

@Component({
    templateUrl: './param.applicatif.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})
export class ParamApplicatifComponent implements OnInit, OnDestroy {

    natureDialog: boolean;
    natures: Nature[];
    nature: Nature;
    copyNature:Nature[];


    niveauDialog: boolean;
    niveaux: Niveau[];
    niveau: Niveau;

    paysDialog: boolean;
    paysList: Pays[];
    pays: Pays;
    
    groupeDialog: boolean;
    groupes: Groupe[];
    groupe: Groupe;
   
    operateurMobileDialog: boolean;
    operateurMobiles: OperateurMobile[];
    operateurMobile: OperateurMobile;
    
    contrainteDialog: boolean;
    contraintes: Contrainte[];
    contrainte: Contrainte;

    entities: any[];
    paysSelected: any;
    
    submitted: boolean;
    
    groupeNatureSelected: any;
    fieldFilter: any;
    subscriptionNature: Subscription;
    subscriptionNiveau: Subscription;
    subscriptionPays: Subscription;
    subscriptionGroupe: Subscription;
    subscriptionOperateurMobile: Subscription;
    subscriptionContrainte: Subscription;

    constructor(private messageService: MessageService,
                private natureService: NatureService,
                private niveauService: NiveauService,
                private paysService: PaysService,
                private groupeService: GroupeService,
                private operateurMobileService: OperateurMobileService,
                private contrainteService: ContrainteService,
                private utils: Utils,
                private confirmationService: ConfirmationService) {

                    this.entities = [
                        {libelle: '-'},
                        {libelle: 'Academicien', code: 'academicien'},
                        {libelle: 'Produit', code: 'produit'},
                    ];

                    this.fieldFilter = {groupeNatureSelected : this.entities[0]}
                }

    ngOnInit() {
        this.natures = [];
        this.niveaux = [];
        this.paysList = [];
        this.groupes = [];
        this.operateurMobiles = [];
        this.contraintes = [];

        this.subscriptionNature = this.natureService.entitiesSubject
            .subscribe((natures: Nature[]) => {
                this.natures = natures;
                this.copyNature = natures;
            });

        this.subscriptionNiveau = this.niveauService.entitiesSubject
            .subscribe((niveaux: Niveau[]) => {
                this.niveaux = niveaux;
            });

        this.subscriptionPays = this.paysService.entitiesSubject
            .subscribe((paysList: Pays[]) => {
                this.paysList = paysList;
            });

        this.subscriptionGroupe= this.groupeService.entitiesSubject
            .subscribe((groupes: Groupe[]) => {
                this.groupes = groupes;
            });

        this.subscriptionOperateurMobile = this.operateurMobileService.entitiesSubject
            .subscribe((operationMobiles: OperateurMobile[]) => {
                this.operateurMobiles = operationMobiles;
            });

        this.subscriptionContrainte = this.contrainteService.entitiesSubject
            .subscribe((contraintes: Contrainte[]) => {
                this.contraintes = contraintes;
            });

        this.subscriptionNature = this.natureService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.natureService.action === "create"){
                        if(this.copyNature){
                            this.copyNature.splice(0, 0, response.items[0]); // inserer en tete de liste
                            this.natures.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.natures = [response.items[0]];
                            this.copyNature = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de nature effectuée avec success', life: 3000});
                        this.natureDialog = false;
                        this.nature = {};
                        // this.copyNature = [...this.natures]
                    }
                    if(this.natureService.action === "update"){
                        let index = this.utils.findIndexById(this.natures, response.items[0].id).index;
                        this.copyNature[index] = response.items[0];    
                        // this.copyNature = [...this.natures]
                        this.messageService.add({severity:'success', summary: 'Mise à jour de nature effectuée avec success', life: 3000});
                    }
                    if(this.natureService.action === "delete"){
                        let index = this.utils.findIndexById(this.natures, this.nature.id).index;
                        this.copyNature.splice(index,1);
                        this.nature = {};
                        // this.copyNature = [...this.natures]
                        this.messageService.add({severity:'success', summary: 'Suppression de nature effectuée avec success', life: 3000});
                    }

                    this.natureService.entities = [...this.natures];
                }
            });

        this.subscriptionNiveau = this.niveauService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.niveauService.action === "create"){
                        if(this.niveaux){
                            this.niveaux.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.niveaux = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de niveau effectuée avec success', life: 3000});
                        this.niveauDialog = false;
                        this.niveau = {};
                    }
                    if(this.niveauService.action === "update"){
                        let index = this.utils.findIndexById(this.niveaux, response.items[0].id).index;
                        this.niveaux[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de niveau effectuée avec success', life: 3000});
                    }
                    if(this.niveauService.action === "delete"){
                        let index = this.utils.findIndexById(this.niveaux, this.niveau.id).index;
                        this.niveaux.splice(index,1);
                        this.niveau = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de niveau effectuée avec success', life: 3000});
                    }

                    this.niveauService.entities = [...this.niveaux];
                }
            });

        this.subscriptionPays = this.paysService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.paysService.action === "create"){
                        if(this.paysList){
                            this.paysList.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.paysList = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de pays effectuée avec success', life: 3000});
                        this.paysDialog = false;
                        this.pays = {};
                    }
                    if(this.paysService.action === "update"){
                        let index = this.utils.findIndexById(this.paysList, response.items[0].id).index;
                        this.paysList[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de pays effectuée avec success', life: 3000});
                    }
                    if(this.paysService.action === "delete"){
                        let index = this.utils.findIndexById(this.paysList, this.pays.id).index;
                        this.paysList.splice(index,1);
                        this.pays = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de pays effectuée avec success', life: 3000});
                    }

                    this.paysService.entities = [...this.paysList];
                }
            });

        this.subscriptionGroupe = this.groupeService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.groupeService.action === "create"){
                        if(this.groupes){
                            this.groupes.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.groupes = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de groupe effectuée avec success', life: 3000});
                        this.groupeDialog = false;
                        this.groupe = {};
                    }
                    if(this.groupeService.action === "update"){
                        let index = this.utils.findIndexById(this.groupes, response.items[0].id).index;
                        this.groupes[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de groupe effectuée avec success', life: 3000});
                    }
                    if(this.groupeService.action === "delete"){
                        let index = this.utils.findIndexById(this.groupes, this.groupe.id).index;
                        this.groupes.splice(index,1);
                        this.groupe = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de groupe effectuée avec success', life: 3000});
                    }

                    this.groupeService.entities = [...this.groupes];
                }
            });

        this.subscriptionOperateurMobile = this.operateurMobileService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.operateurMobileService.action === "create"){
                        if(this.operateurMobiles){
                            this.operateurMobiles.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.operateurMobiles = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de operateurMobile effectuée avec success', life: 3000});
                        this.operateurMobileDialog = false;
                        this.operateurMobile = {};
                    }
                    if(this.operateurMobileService.action === "update"){
                        let index = this.utils.findIndexById(this.operateurMobiles, response.items[0].id).index;
                        this.operateurMobiles[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de operateurMobile effectuée avec success', life: 3000});
                    }
                    if(this.operateurMobileService.action === "delete"){
                        let index = this.utils.findIndexById(this.operateurMobiles, this.operateurMobile.id).index;
                        this.operateurMobiles.splice(index,1);
                        this.operateurMobile = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de operateurMobile effectuée avec success', life: 3000});
                    }

                    this.operateurMobileService.entities = [...this.operateurMobiles];
                }
            });

        this.subscriptionContrainte = this.contrainteService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.contrainteService.action === "create"){
                        if(this.contraintes){
                            this.contraintes.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.contraintes = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de contrainte effectuée avec success', life: 3000});
                        this.contrainteDialog = false;
                        this.contrainte = {};
                    }
                    if(this.contrainteService.action === "update"){
                        let index = this.utils.findIndexById(this.contraintes, response.items[0].id).index;
                        this.contraintes[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de contrainte effectuée avec success', life: 3000});
                    }
                    if(this.contrainteService.action === "delete"){
                        let index = this.utils.findIndexById(this.contraintes, this.contrainte.id).index;
                        this.contraintes.splice(index,1);
                        this.contrainte = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de contrainte effectuée avec success', life: 3000});
                    }

                    this.contrainteService.entities = [...this.contraintes];
                } 
            });

          this.natureService.emitEntities();
          this.niveauService.emitEntities();
          this.groupeService.emitEntities();
          this.operateurMobileService.emitEntities();
          this.paysService.emitEntities();
          this.contrainteService.emitEntities();
    }
    
    openNewNatureDialog() {
        this.nature = {};
        this.groupeNatureSelected = "academicien";
        this.submitted = false;
        this.natureDialog = true;
    }

    openEditNatureDialog(nature: Nature) {
        this.nature = {...nature};
        this.groupeNatureSelected = "produit";
        if(this.nature.isAcademicien){
            this.groupeNatureSelected = "academicien";
        }
        this.natureDialog = true;
    }

    deleteNature(nature: Nature) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + nature.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.natureService._request = {};
                this.natureService._request.datas = [nature];
                this.nature = nature;
                this.natureService.action = "delete";
                this.natureService.makeRequest();
            }
        });
    }

    hideDialog() {
        this.natureDialog = false;
        this.niveauDialog = false;
        this.paysDialog = false;
        this.groupeDialog = false;
        this.operateurMobileDialog = false;
        this.contrainteDialog = false;
        this.submitted = false;
    }
    
    saveNature() {
        this.submitted = true;
        if(this.groupeNatureSelected == "academicien"){
            this.nature.isAcademicien = true;
        }
        if(this.groupeNatureSelected == "produit"){
            this.nature.isAcademicien = false;
        }
        if (this.nature.libelle.trim()) {
            if (this.nature.id) {
                this.natureService.action = "update";
            }
            else {
                this.natureService.action = "create";
            }
            this.natureService._request = {};
            this.natureService._request.datas = [this.nature];

            this.natureService.makeRequest();    
        }
    }

    openNewNiveauDialog() {
        this.niveau = {};
        this.submitted = false;
        this.niveauDialog = true;
    }

    openEditNiveauDialog(niveau: Niveau) {
        this.niveau = {...niveau};
        this.niveauDialog = true;
    }

    deleteNiveau(niveau: Niveau) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + niveau.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.niveauService._request = {};
                this.niveauService._request.datas = [niveau];
                this.niveau = niveau;
                this.niveauService.action = "delete";
                this.niveauService.makeRequest();
            }
        });
    }
    
    saveNiveau() {
        this.submitted = true;
        if (this.niveau.libelle.trim()) {
            if (this.niveau.id) {
                this.niveauService.action = "update";
            }
            else {
                this.niveauService.action = "create";
            }
            this.niveauService._request = {};
            this.niveauService._request.datas = [this.niveau];

            this.niveauService.makeRequest();    
        }
    }

    openNewGroupeDialog() {
        this.groupe = {};
        this.submitted = false;
        this.groupeDialog = true;
    }

    openEditGroupeDialog(groupe: Groupe) {
        this.groupe = {...groupe};
        this.groupeDialog = true;
    }

    deleteGroupe(groupe: Groupe) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + groupe.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.groupeService._request = {};
                this.groupeService._request.datas = [groupe];
                this.groupe = groupe;
                this.groupeService.action = "delete";
                this.groupeService.makeRequest();
            }
        });
    }
    
    saveGroupe() {
        this.submitted = true;
        if (this.groupe.libelle.trim()) {
            if (this.groupe.id) {
                this.groupeService.action = "update";
            }
            else {
                this.groupeService.action = "create";
            }
            this.groupeService._request = {};
            this.groupeService._request.datas = [this.groupe];

            this.groupeService.makeRequest();    
        }
    }

    openNewPaysDialog() {
        this.pays = {};
        this.submitted = false;
        this.paysDialog = true;
    }

    openEditPaysDialog(pays: Pays) {
        this.pays = {...pays};
        this.paysDialog = true;
    }

    deletePays(pays: Pays) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + pays.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.paysService._request = {};
                this.paysService._request.datas = [pays];
                this.pays = pays;
                this.paysService.action = "delete";
                this.paysService.makeRequest();
            }
        });
    }
    
    savePays() {
        this.submitted = true;
        if (this.pays.libelle.trim()) {
            if (this.pays.id) {
                this.paysService.action = "update";
            }
            else {
                this.paysService.action = "create";
            }
            this.paysService._request = {};
            this.paysService._request.datas = [this.pays];

            this.paysService.makeRequest();    
        }
    }

    openNewOperateurMobileDialog() {
        this.operateurMobile = {};
        // this.operateurMobile = {paysId:1};
        this.submitted = false;
        this.operateurMobileDialog = true;
    }

    openEditOperateurMobileDialog(operateurMobile: OperateurMobile) {
        this.operateurMobile = {...operateurMobile};
        this.operateurMobileDialog = true;
    }

    deleteOperateurMobile(operateurMobile: OperateurMobile) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + operateurMobile.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.operateurMobileService._request = {};
                this.operateurMobileService._request.datas = [operateurMobile];
                this.operateurMobile = operateurMobile;
                this.operateurMobileService.action = "delete";
                this.operateurMobileService.makeRequest();
            }
        });
    }
    
    saveOperateurMobile() {
        this.submitted = true;
        if (this.operateurMobile.libelle.trim()) {
            if (this.operateurMobile.id) {
                this.operateurMobileService.action = "update";
            }
            else {
                this.operateurMobileService.action = "create";
            }
            this.operateurMobileService._request = {};
            this.operateurMobileService._request.datas = [this.operateurMobile];

            this.operateurMobileService.makeRequest();    
        }
    }


    openNewContrainteDialog() {
        this.contrainte = {};
        this.submitted = false;
        this.contrainteDialog = true;
    }

    openEditContrainteDialog(contrainte: Contrainte) {
        this.contrainte = {...contrainte};
        this.contrainteDialog = true;
    }

    deleteContrainte(contrainte: Contrainte) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + contrainte.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.contrainteService._request = {};
                this.contrainteService._request.datas = [contrainte];
                this.contrainte = contrainte;
                this.contrainteService.action = "delete";
                this.contrainteService.makeRequest();
            }
        });
    }
    
    saveContrainte() {
        this.submitted = true;
        if (this.contrainte.id) {
            this.contrainteService.action = "update";
        }
        else {
            this.contrainteService.action = "create";
        }
        this.contrainteService._request = {};
        this.contrainteService._request.datas = [this.contrainte];

        this.contrainteService.makeRequest();    
    }

    entitySelectedFilter(e){
        this.natures = [];
        if(e.value=="academicien"){
            for(let nature of this.copyNature){
                if(nature.isAcademicien){
                    this.natures.push(nature);
                }
            }
            return;
        }
        if(e.value=="produit"){
            for(let nature of this.copyNature){
                if(!nature.isAcademicien){
                    this.natures.push(nature);
                }
            }
            return;
        }
        for(let nature of this.copyNature){
            this.natures.push(nature);
        }


        
    }

    ngOnDestroy(): void {
        this.subscriptionNature.unsubscribe();
        this.subscriptionNiveau.unsubscribe();
        this.subscriptionPays.unsubscribe();
        this.subscriptionGroupe.unsubscribe();
        this.subscriptionOperateurMobile.unsubscribe();
        this.subscriptionContrainte.unsubscribe();
    }

}
