import {Component, OnDestroy, OnInit} from '@angular/core';
import { MessageService} from 'primeng/api';
import { Subscription } from 'rxjs';
import { Pays } from '../domain/academicien';
import { User } from '../domain/user';
import { _Response } from '../domain/_response';
import { PaysService } from '../service/PaysService';
import { UserService } from '../service/userService';
import { StaticMessage } from '../utilities/staticMessage';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashbord.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .fc-header-toolbar {
                display: flex;
                flex-wrap: wrap;
            }
        }
    `],
    providers: [MessageService]

})
export class DashboardComponent  implements OnInit, OnDestroy{


    user: User[];
    datasDashbord: any;
    datasSolde: any;
    datasAcademicien: any[];
    datasMessageAssistance: any[];

    paysList: Pays[];
    submitted: boolean;

    subscriptionUser: Subscription;
    subscriptionPays: Subscription;

    constructor(private userService: UserService,
                private paysService: PaysService,
                private messageService: MessageService,
                ) {}

    ngOnInit() {

        this.datasDashbord = {};
        this.datasSolde = {};
        this.datasMessageAssistance = [];

        this.subscriptionPays = this.paysService.entitiesSubject
        .subscribe((paysList: Pays[]) => {
            if(paysList){
                this.paysList = paysList;

                this.userService._request = {};
                console.log('pays selected number ',this.paysService.paysSelectedCodeNumero);
                this.userService._request.data = {paysCodeNumero:this.paysService.paysSelectedCodeNumero};

                this.userService.action = "getInfosDashboard";
                this.userService.makeRequest();

            }
        });

        this.paysService.emitEntities();

        this.subscriptionUser = this.userService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.userService.action === "getInfosDashboard"){
                        if(response.items && response.items[0].portefeuilleAcademicien){
                            this.datasDashbord = response.items[0];
                            this.datasAcademicien = this.datasDashbord.datasAcademicien;
                            if(this.datasDashbord.datasMessageAssistance){
                                for(let msg of this.datasDashbord.datasMessageAssistance){
                                    console.log('le massage assistance : ', msg);
                                    if(msg.discussionEtatCode == StaticMessage.ETAT_EN_COURS){
                                        this.datasMessageAssistance.push(msg);
                                    }
                                }
                            }

                            this.userService.action = "getCinetPayAndSmsSolde";
                            this.userService.makeRequest();
                            return;
                        }
                    }

                    if(this.userService.action === "getCinetPayAndSmsSolde"){
                        if(response.items){
                            this.datasSolde = response.items[0];
                            return;
                        }
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.subscriptionUser.unsubscribe();
        this.subscriptionPays.unsubscribe();
    }

}
