import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { Utils } from './utils';
import { Niveau } from '../domain/academicien';


@Injectable()
export class NiveauService extends AbstractService<Niveau>{
  table = "niveau";
  libelle ="Niveau";

  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
    this.getEntities();
  }

  getHttp() {
    return this.http;
  }
  getUtils(){
    return this.utils;
  }
}