import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateChild, CanLoad, Route } from '@angular/router';
import { User } from '../domain/user';
import { UserService } from '../service/userService';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate  {
  user: User;
  precUrl:string;
  constructor(private router: Router, 
              private userService: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
    const url: string = state.url;
    
    return this.checkLogin(url);
  }

  checkLogin(url: string): true|UrlTree {
    // var splitted = url.split("/", 4); 
    this.user = this.userService.getConnectUser();
    
    if(this.user && this.user.id){
      // s'il est connecté, verifier access
      return this.checkAcces(url, this.user);
      // return true;
    }
    return this.router.parseUrl('/login');
  }

  checkAcces(url: string, user:User): true|UrlTree{
    console.log("en cours verifier access : ", this.user.id);

    var splitted = url.split("/", 6); 

    if((splitted[1]=="dashboard") && user.roleIsDashbord){
      return true;
    }
    if((splitted[1]=="statistique") && user.roleIsStatistique){
      return true;
    }
    if((splitted[1]=="academicien") && user.roleIsAcademicien){
      return true;
    }
    if((splitted[1]=="operation") && user.roleIsOperation){
      return true;
    }
    if((splitted[1]=="produit") && user.roleIsProduit){
      return true;
    }
    if((splitted[1]=="production") && user.roleIsProduction){
      return true;
    }
    if((splitted[1]=="position") && user.roleIsPosition){
      return true;
    }
    if((splitted[1]=="moderation") && user.roleIsModeration){
      return true;
    }
    if((splitted[1]=="publication") && user.roleIsPublication){
      return true;
    }
    if((splitted[1]=="assistance") && user.roleIsAssistance){
      return true;
    }
    if((splitted[2]=="applicatif") && user.roleIsApplicatif){
      return true;
    }
    if((splitted[2]=="utilisateur") && user.roleIsUtilisateur){
      return true;
    }

   // Redirect vers access-denied page
   return this.router.parseUrl('/accessdenied');
  }
}