import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Academicien, Groupe, Pays } from '../domain/academicien';
import { Commentaire } from '../domain/commentaire';
import { Etat } from '../domain/etat';
import { Operation } from '../domain/operation';
import { Categorie, Produit } from '../domain/produit';
import { TypeOperation } from '../domain/typeOperation';
import { _Response } from '../domain/_response';
import { AcademicienService } from '../service/academicienService';
import { CategorieService } from '../service/categorieService';
import { CommentaireService } from '../service/commentaireService';
import { EtatService } from '../service/etatService';
import { GroupeService } from '../service/groupeService';
import { OperationService } from '../service/operationService';
import { PaysService } from '../service/PaysService';
import { ProduitService } from '../service/produitService';
import { TypeOperationService } from '../service/typeOperationService';
import { UserService } from '../service/userService';
import { StaticMessage } from '../utilities/staticMessage';

@Component({
    templateUrl: './statistique.component.html',
    styles: [`
        .table_wrapper{
            display: block;
            overflow-x: auto;
            white-space: nowrap;
        }
        
`],
    providers: [MessageService, ConfirmationService]
})



export class StatistiqueComponent implements OnInit, OnDestroy {
    
    scanParCategorie: any;
    etatAcademicien: any;
    transactionParOperateur: any;
    // operationStatDatas: OperationStatDatas;

    colors: string[];

    // labels;
    // datasetsData;
    // datasetsBackgroundColor;
    // datasets;
    // indexColor = 0;

    academicien: Academicien;
    academicienShowDialog: boolean;
    operations: Operation[];
    etats: Etat[];
    etatOperations: Etat[];
    typeOperations: TypeOperation[];
    produits: Produit[];
    totalRecord: number;
    commentaires: Commentaire[];

    
    classementAcademiciens: any[];
    categorieProduitPourClassements: string [];
    groupementAcademicien: any;
    groupes: Groupe[];

    moisForStat: string[];
    chartOptions: any;
    annees:any[];
    mois:any[];
    options: any[];
    submitted: boolean;
    datasOperateur:any[];
    montantTransactions:number[];
    datasCategorie: any[];
    abscisseGraphes: any[];
    categorieProduits: Categorie[];
    fieldFilter: any;

    isDisabled: boolean;

    subscriptionAcademicien:Subscription;
    subscriptionGroupe:Subscription;
    subscriptionUser:Subscription;
    subscriptionPays:Subscription;
    subscriptionCategorieProduit:Subscription;

    subscriptionProduit: Subscription;
    subscriptionEtat: Subscription;
    subscriptionTypeOperation: Subscription;
    subscriptionOperation: Subscription;
    subscriptionCommentaire: Subscription;

    
    constructor(private academicienService:AcademicienService,
                private userService: UserService,
                private messageService: MessageService,
                private groupeService: GroupeService,
                private etatService: EtatService, 
                private operationService: OperationService, 
                private typeOperationService: TypeOperationService, 
                private commentaireService: CommentaireService, 
                private produitService: ProduitService,
                private categorieProduitService: CategorieService,
                private paysService: PaysService){

                    this.options = [
                        {libelle:'Nombre scan',code:StaticMessage.NBRE_SCAN},
                        {libelle:'Montant scan',code:StaticMessage.MONTANT_SCAN}
                    ];

                    this.colors = ['#58D68D','#36a2eb','#ffc456','#f70538','#F7DC6F','#ff6384','#A569BD','#5D6D7E','#f77e45','#F1948A'];
                    this.moisForStat = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Juil','Aout','Sep','Oct','Nov','Dev']
                }
   

    ngOnInit() {
        this.datasOperateur = [];
        this.montantTransactions = [];
        this.fieldFilter = {};
        this.fieldFilter.dateDebut = new Date(new Date().setDate(new Date().getDate()-(30*3)));
        this.fieldFilter.dateFin = new Date();
        this.fieldFilter.groupeId = null;
        this.fieldFilter.optionClassement = StaticMessage.NBRE_SCAN;
        this.fieldFilter.etatId = null;
        this.fieldFilter.produitId = null;
        this.fieldFilter.typeOperationCode = null;

        this.operations = [];
        this.etatOperations = [];
        this.etats = [];
        this.produits = [];
        this.typeOperations = [];
        this.isDisabled = true;
        this.totalRecord = 0;   

        // this.fieldFilter = {groupeId:null, optionClassement:StaticMessage.NBRE_SCAN};

        var defaultDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
        var defaultDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();

        this.subscriptionPays = this.paysService.entitiesSubject
        .subscribe((paysList: Pays[]) => {
            if(paysList){
                // calssement academicien
                this.academicienService._request = {};
                this.academicienService._request.data = {
                    paysCodeNumero:this.paysService.paysSelectedCodeNumero,
                    dateDebut:defaultDateDebut, 
                    dateFin:defaultDateFin, 
                    optionClassement:StaticMessage.NBRE_SCAN,
                    groupeId:null
                };
            
                this.academicienService._request.size = '5';
                this.academicienService._request.index = 0;
                this.academicienService.action = "classementAcademicien";
                this.isDisabled = true;
                this.academicienService.makeRequest();

                // regroupement par categorie
                this.userService._request = {};
                this.userService._request.data = {paysCodeNumero:this.paysService.paysSelectedCodeNumero};
                this.userService.action = "academicienParEtat";
                this.userService.makeRequest();
            }
        });
        
        this.subscriptionGroupe = this.groupeService.entitiesSubject
            .subscribe((groupes: Groupe[]) => {
                if(groupes){
                    this.groupes = groupes;
                    this.groupes.splice(0, 0,  {id:null, libelle: '-'});
                } 
            });
       
         this.subscriptionCategorieProduit = this.categorieProduitService.entitiesSubject
            .subscribe((categories: Categorie[]) => {
                if(categories){
                    this.categorieProduits = categories;
                    this.categorieProduits.splice(0, 0,  {id:null, libelle: '-'});
                } 
            });

            this.subscriptionProduit = this.produitService.entitiesSubject
            .subscribe((produits: Produit[]) => {
                if(produits){
                    this.produits = produits;
                    if(this.produits[0].libelle!='-'){
                        this.produits.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionEtat = this.etatService.entitiesSubject
            .subscribe((etats: Etat[]) => {
                if(etats){
                    var etatTempon: Etat[] = [];
                    for(let etat of etats){
                        if(etat.type == StaticMessage.TYPE_ACADEMICIEN){
                            etatTempon.push( etat);
                        }
                        if(etat.type == StaticMessage.TYPE_OPERATION){
                            this.etatOperations.push( etat);
                        }
                    }
                    this.etats = etatTempon;
                    if(this.etats[0].libelle!='-'){
                        this.etats.splice(0, 0,  {id:null, libelle: '-'});
                    }
                    if(this.etatOperations[0].libelle!='-'){
                        this.etatOperations.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionTypeOperation = this.typeOperationService.entitiesSubject
            .subscribe((typeOperations: TypeOperation[]) => {
                if(typeOperations){
                    this.typeOperations = typeOperations;
                    if(this.typeOperations[0].libelle!='-'){
                        this.typeOperations.splice(0, 0,  {code:null, libelle: '-'});
                    }
                }
            });

        this.produitService.emitEntities();
        this.etatService.emitEntities();
        this.typeOperationService.emitEntities();
    
        
        this.paysService.emitEntities();
        this.groupeService.emitEntities();
        this.categorieProduitService.emitEntities();


        this.subscriptionAcademicien = this.academicienService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.academicienService.action === "classementAcademicien"){
                        this.isDisabled = false;
                        if(response.items){
                            this.classementAcademiciens = [...response.items];
                            if(this.academicienService._request.data.categorieProduitId && response.items[0].datasLibelleCategorie){
                                this.categorieProduitPourClassements = [response.items[0].datasLibelleCategorie[0]];
                                return;
                            }
                            
                            if(response.items[0] && response.items[0].datasLibelleCategorie){
                                this.categorieProduitPourClassements = response.items[0].datasLibelleCategorie;
                                return;
                            }
                           
                        }
                        this.classementAcademiciens = [];
                        this.categorieProduitPourClassements = [];
                    }
                    if(this.academicienService.action === "getByCriteria"){
                        if(response.items){
                            this.academicien = {...response.items[0]};
                            this.academicienShowDialog = true;
                        }else {
                            this.messageService.add({severity:'error', summary: 'Cet academicien à été supprimer du système', life: 3000});
                        }
                    }
                }
            });

        this.subscriptionUser = this.userService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.userService.action === "academicienParEtat"){
                        // statistique operation 
                        this.userService._request = {};

                        this.userService._request.data = {
                            paysCodeNumero:this.paysService.paysSelectedCodeNumero,
                            dateDebut:defaultDateDebut, 
                            dateFin:defaultDateFin 
                        }     

                        if(response.items){
                            var itemList = [...response.items];
                            var labels = [];
                            var data = [];
                            var backgroundColor = [];
                            var datasets = [];
                            var indexColor = 0;
                            for(let item of itemList){
                                labels.push(item.libelle);
                                data.push(item.valeur);
                                backgroundColor.push(item.codeCouleur);
                                indexColor++;
                            }
                            datasets = [{data, backgroundColor}];
                            this.groupementAcademicien = {labels, datasets};
                        }else {
                            this.groupementAcademicien = {};
                        }
                        response.items = null;
                        this.userService.action = "getStatistique";
                        this.isDisabled = true;
                        this.userService.makeRequest();
                        return;
                    }
                    
                    if(this.userService.action === "getStatistique"){
                        this.isDisabled = false;

                        this.userService.action = "";
                        if(response.items){
                            var indexColor = 0;
                            var datasetsTempon = [];
                            this.datasOperateur = response.items[0].datasOperateur;
                            this.datasCategorie = response.items[0].datasCategorie;
                            this.abscisseGraphes = response.items[0].abscisseGraphes;

                            // transaction par operateur
                            if(response.items[0]){
                                this.montantTransactions = [];
                                if(response.items[0].datasOperateur){

                                    for(let abs = 0; abs < this.abscisseGraphes.length; abs++){
                                        var montant = 0;

                                        for(let op of response.items[0].datasOperateur){
                                            montant = montant+op.montantTransaction[abs];
                                        }
                                        // total pour 1 mois 
                                        this.montantTransactions.push(montant);

                                    }

                                    console.log('--- montantTransactions -- : ',this.montantTransactions);

                                    for(let d of response.items[0].datasOperateur){
                                        datasetsTempon.push(
                                            {type :  'line',label :"", borderColor : this.colors[indexColor],
                                            borderWidth : 2, fill : false, data : d.nbreTransaction}
                                        );
                                        indexColor++;
                                    }
                                    indexColor = 0;
                                    for(let d of response.items[0].datasOperateur){
                                        datasetsTempon.push(
                                            {type :  'bar',label : d.libelle, backgroundColor: this.colors[indexColor],
                                            borderColor : 'white', borderWidth : 2, fill : false, data : d.nbreTransaction}
                                        );
                                        indexColor++
                                    }
                                    this.transactionParOperateur = {labels :response.items[0].abscisseGraphes, datasets: datasetsTempon};
                                }
                                
                                // scan par categorie de produit
                                if(response.items[0].datasCategorie){
                                    var datasets1 = [];
                                    indexColor = 0;
                                    for(let d of response.items[0].datasCategorie){
                                        datasets1.push(
                                            {label : d.libelle,  data : d.nbreScan,
                                                fill : false, backgroundColor : this.colors[indexColor],
                                                borderColor : this.colors[indexColor]});
    
                                        indexColor++;
    
                                    }
                                    this.scanParCategorie = {
                                        labels: response.items[0].abscisseGraphes,
                                        datasets: datasets1
                                    };
                                    return;
                                }
                            }
                            this.transactionParOperateur = {};
                            this.scanParCategorie = {};
                        }
                    }
                }
            });



            // this.subscriptionAcademicien = this.academicienService.responseSubject
            // .subscribe((response: _Response) => {
            //     this.isDisabled = false;
            //     if(response.hasError){
            //         this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
            //     }else {
            //         if(this.academicienService.action === "getByCriteria"){
            //             if(response.items){
            //                 this.academicien = {...response.items[0]};
            //                 this.academicienShowDialog = true;
            //             }else {
            //                 this.messageService.add({severity:'error', summary: 'Cet academicien à été supprimer du système', life: 3000});
            //             }
            //         }
            //     }
            // });

            this.subscriptionOperation = this.operationService.responseSubject
                .subscribe((response: _Response) => {
                    this.submitted = false;
                    this.isDisabled = false;
                    if(response.hasError){
                        this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                    }else {
                        if(this.operationService.action === "getByCriteria"){
                            if(response.items){
                                this.operations = [...response.items];
                                this.totalRecord = response.count;
                                return;
                            }
                            this.operations = [];
                            this.totalRecord = 0;
                        }
                    }
                });

            this.subscriptionCommentaire = this.commentaireService.responseSubject
                .subscribe((response: _Response) => {
                    this.submitted = false;
                    this.isDisabled = false;
                    if(response.hasError){
                        this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                    }else {
                        if(this.commentaireService.action === "getByCriteria"){
                            if(response.items){
                                this.commentaires = [...response.items];
                                this.totalRecord = response.count;
                                return;
                            }
                            this.commentaires = [];
                            this.totalRecord = 0;
                        }
                    }
                });

    }


    chercherAcademicien(){
        this.academicienService._request = {};
    
        let newDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
        let newDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();

        this.academicienService._request.data = {
            paysCodeNumero:this.paysService.paysSelectedCodeNumero,
            dateDebut:newDateDebut,
            dateFin:newDateFin,
            groupeId:this.fieldFilter.groupeId, 
            optionClassement:this.fieldFilter.optionClassement,
            categorieProduitId:this.fieldFilter.categorieProduitId
        };

        this.academicienService._request.size = '5';
        this.academicienService._request.index = 0;

        this.academicienService.action = "classementAcademicien";
        this.isDisabled = true;
        this.academicienService.makeRequest();
    }   

    getStatisticByDate(){
        this.isDisabled = true;

        this.userService._request = {};

        let newDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
        let newDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();

        this.userService._request.data = {
            paysCodeNumero:this.paysService.paysSelectedCodeNumero,
            dateDebut:newDateDebut,
            dateFin:newDateFin
        };

        this.userService.action = "getStatistique";
        this.userService.makeRequest();

    }

    showAcademcien(idAcademicien : number){
        this.academicienService._request = {};
        this.academicienService._request.data = {id:idAcademicien};
        this.academicienService.action = "getByCriteria";
        this.academicienService.makeRequest();
        this.isDisabled = true;
        this.academicien = {};
    }
    
    hideDialog() {
        this.academicienShowDialog = false;
        this.isDisabled = false;
    }
    

    initProduitDropdown(){
        this.fieldFilter.produitId = null;
    }

    loadOperation(event){
        //this.isDisabled = false;
        
        this.operationService._request = {};
        this.operationService._request.data = { etatId:this.fieldFilter.etatId,
                                                typeOperationCode:this.fieldFilter.typeOperationCode,
                                                academicienId: this.academicien.id,
                                                produitId: this.fieldFilter.produitId
                                                };
        
        this.operationService._request.size = event.rows;
        this.operationService._request.index = event.first/event.rows;
        this.operationService.action = "getByCriteria";
     
         this.operationService.makeRequest();

      //  this.criteriaSearchOperation(event.rows, event.first/event.rows);
    }

    loadCommentaire(event){
        this.criteriaSearchCommentaire(event.rows, event.first/event.rows);
    }
    

    criteriaSearchOperation(size:string, index:number){
        this.isDisabled = true;

        this.operationService._request = {};
        this.operationService._request.data = { etatId:this.fieldFilter.etatId,
                                                typeOperationCode:this.fieldFilter.typeOperationCode,
                                                academicienId: this.academicien.id,
                                                produitId: this.fieldFilter.produitId
                                                };
        
        this.operationService._request.size = size;
        this.operationService._request.index = index;
        this.operationService.action = "getByCriteria";
     
         this.operationService.makeRequest();
    }

    criteriaSearchCommentaire(size:string, index:number){
       // this.isDisabled = true;

        if(!this.commentaires || !this.commentaires.length){
            this.commentaireService._request = {};
            this.commentaireService._request.data = { };
            
            // this.operationService._request.size = size;
            // this.operationService._request.index = index;
            this.commentaireService.action = "getByCriteria";
         
             this.commentaireService.makeRequest();
        }
    }


    arrondi(val:number){
        return Math.round(val*100)/100;
    }

    ngOnDestroy(): void {
        this.subscriptionAcademicien.unsubscribe();
        this.subscriptionGroupe.unsubscribe();
        this.subscriptionUser.unsubscribe();
        this.subscriptionPays.unsubscribe();
        this.subscriptionCategorieProduit.unsubscribe();

        this.subscriptionProduit.unsubscribe();
        this.subscriptionEtat.unsubscribe();
        this.subscriptionTypeOperation.unsubscribe();
        this.subscriptionOperation.unsubscribe();
        this.subscriptionCommentaire.unsubscribe();
        
        this.groupes.splice(0,1);
        this.categorieProduits.splice(0,1);
        this.produits.splice(0,1);
        this.typeOperations.splice(0,1);
        this.etats.splice(0,1);
    }
    
}
