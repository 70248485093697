import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { GroupeService } from '../service/groupeService';
import { Groupe } from '../domain/academicien';
import { Diffusion } from '../domain/diffusion';
import { DiffusionService } from '../service/diffusionService';
import { WebsocketService } from '../service/websocketService';

@Component({
    templateUrl: './moderation.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})
export class ModerationComponent implements OnInit, OnDestroy{

    moderations: Diffusion[];
    moderation: Diffusion;

    groupe: Groupe;
    groupes: Groupe[];
    selectedGroupes: Groupe[];
    items: MenuItem[];

    submitted: boolean;
    isDisabled: boolean;

    subscriptionModeration: Subscription;
    subscriptionGroupe: Subscription;
    subscriptionWebsocket: Subscription;
        
    constructor(private moderationService: DiffusionService, 
                private groupeService: GroupeService,
                private messageService: MessageService,
                private websocketService: WebsocketService,
                private confirmationService: ConfirmationService,
                private utils: Utils) {}
   
    ngOnInit() {
        this.moderation = {};
        this.groupe = {};
        this.groupes = [];
        // this.subscriptionGroupe= this.groupeService.entitiesSubject
        //     .subscribe((groupes: Groupe[]) => {
        //         this.groupes = groupes;
        //     });

        // this.groupeService.emitEntities();
        // this.items = [
        //     {label: 'Image', icon: 'pi pi-refresh', command: () => {
        //         this.update();
        //     },
        //     {label: 'Video', icon: 'pi pi-times', command: () => {
        //         this.delete();
        //     }}
        // ];


        
        this.websocketService.connect();

        this.groupeService._request = {};
        this.groupeService._request.data = {};

        this.groupeService.action = "getGroupeAcademicienNbre";
        this.groupeService.makeRequest();
        
        this.moderation = {};

        this.subscriptionWebsocket = this.websocketService.messageSubject.subscribe(msg => {
            console.log("Response from websocket: " , msg);
            // mise a jour de nombre message non lu en local 
            if(this.groupe.id == msg.groupeId){ // message de groupe courant 
                if(this.moderations){
                    this.moderations.push(msg); 
                }
                else {
                    this.moderations = [msg];
                }
                // if sender is sippec init field
                if(msg.isSippec){
                    this.moderation = {};
                }
            }
            else {
                for (let i = 0; i < this.groupes.length; i++) {
                    if(this.groupes[i].id == msg.groupeId){
                        if(this.groupes[i].nbreMessageNonLu != null){
                         this.groupes[i].nbreMessageNonLu++;
                        }
                        else {
                         this.groupes[i].nbreMessageNonLu = 0;
                        }
                        break;
                    }
                }
            }
         
          });

          
        this.subscriptionGroupe= this.groupeService.responseSubject
            .subscribe((response: _Response) => {
                if(response.hasError){
                    this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
                }else {
                    if(this.groupeService.action = "getGroupeAcademicienNbre"){
                        if(response.items){
                            this.groupes = [...response.items];
                            return;
                        }
                        this.groupes = [];
                    }
                }
            });

        this.groupeService.emitEntities();

        this.subscriptionModeration = this.moderationService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
                }else {
                    if(this.moderationService.action === "getByCriteria"){
                        if(response.items){
                            this.moderations = [...response.items];
                            return;
                        }
                        this.moderations = [];
                    }
                    if(this.moderationService.action === "getByCriteriaCustom"){
                        if(response.items){
                            this.moderations = [...response.items];
                            // update nombre message non lu 
                            for (let i = 0; i < this.groupes.length; i++) {
                                if(this.groupes[i].id == this.groupe.id ){
                                    this.groupes[i].nbreMessageNonLu = 0;
                                }
                                
                            }
                            return;
                        }
                        this.moderations = [];
                    }

                    if(this.moderationService.action === "create"){
                        // if(this.moderations){
                        //     this.moderations.push(response.items[0]); 
                        //     // for(let prod of response.items){
                        //     //     this.moderations.push(prod); // inserer en fin de liste
                        //     // }
                        // }
                        // else {
                        //     this.moderations = [...response.items];
                        // }
                        // this.messageService.add({severity:'success', summary: 'Message envoyé avec success', life: 3000});
                        // // this.moderationDialog = false;
                        // this.moderation = {};
                    }

                    // if(this.moderationService.action === "update"){
                    //     let index = this.utils.findIndexById(this.moderations, response.items[0].id).index;
                    //     this.moderations[index] = response.items[0];  
                    //     this.messageService.add({severity:'success', summary: 'Mise à jour du moderation effectuée avec success', life: 3000});
                    // }

                    // if(this.moderationService.action === "delete"){// a revoir 
                    //     let index = this.utils.findIndexById(this.moderations, this.moderation.id).index;
                    //     this.moderations.splice(index,1);
                    //     this.moderation = {};
                    //     this.messageService.add({severity:'success', summary: 'Suppression de moderation effectuée avec success', life: 3000});
                    // }
                }
            });
    }

    getConversation(groupe: Groupe){
        // this.groupe = groupe;
        this.moderationService._request = {};
        this.moderationService._request.data = {groupeId:groupe.id};
        this.moderationService.action = "getByCriteriaCustom";
        this.moderationService.makeRequest();
        
        this.moderation = {};
    }

    envoyerMessage(typeMessage : String){
        if(!this.moderation.contenu){
            return;
        }
        this.isDisabled = true;
        this.moderationService._request = {};
        this.moderationService._request.datas = [];

        for(let grp of this.selectedGroupes){
            let msg = {
                groupeId:grp.id, 
                isSippec:true, 
                contenu:this.moderation.contenu, 
                type:typeMessage,
                nomFichier:this.moderation.nomFichier,
                fichierBase64:this.moderation.fichierBase64,     
                thumbnailUrl:this.moderation.thumbnailUrl,      
                isVideo:this.moderation.isVideo      
            };
            this.moderationService._request.datas.push(msg);
        }

     //   this.moderation.isSippec = true;     
      //  this.moderation.groupeId = this.groupe.id;     
        // this.moderationService._request = {};
        // this.moderationService._request.datas = [this.moderation];

        this.moderationService.action = "create";
        this.moderationService.makeRequest();

        if(this.moderation.type != "text"){
            this.moderation = {};
        }
    }

    activeSendFile: boolean;
    onRowSelect(event) {
        this.activeSendFile = true;
        if(this.selectedGroupes.length==1){
            this.groupe = this.selectedGroupes[0];
            this.getConversation(this.groupe);
        }
    }

    onRowUnselect(event) {
      //  this.messageService.add({severity:'info', summary:'Product Unselected',  detail: event.data.name});
    }

    myUploader(event, type) {
        // afficher boite de dialog de confirmation
        this.confirmationService.confirm({
            message: 'Êtes vous sûr de vouloir envoyer cette video : '+ event.files[0].name+' ? ',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: 'sendMedia',
            accept: async () => {
                console.log('---- envoi de media en cours ----');
                const result = await this.toBase64(event.files[0]).catch(e => Error(e)); 
      
                this.moderation.fichierBase64 = result.toString().replace(/^data:(.*,)?/, "");    
                this.moderation.nomFichier = event.files[0].name;
    
                if(type==1){
                    // this.moderation.nomFichierVideo = null;
                    // this.moderation.nomFichierVisuel = event.files[0].name;
                    this.moderation.isVideo = false;
                    this.moderation.type = 'image';
                }
                if(type==2){
                    // this.moderation.nomFichierVisuel = null;
                    // this.moderation.nomFichierVideo = event.files[0].name;
                    this.moderation.isVideo = true;
                    this.moderation.type = 'video';

                    // ThumbnailUrl  a generer 
                    const [file] = event.files;
                    const fileUrl = URL.createObjectURL(file);
                    const thumbUrl = await this.getThumbnailForVideo(fileUrl);

                    // conversion en base64 du thumbnail
                    this.moderation.thumbnailUrl = thumbUrl.toString().replace(/^data:(.*,)?/, "");    

                    // this.moderation.thumbnailUrl = thumbUrl;

                }

                this.moderation.contenu = this.moderation.fichierBase64;
                this.envoyerMessage(this.moderation.type);
            }
        });

    }

    async  getThumbnailForVideo(videoUrl) {
        const video = document.createElement("video");
        const canvas = document.createElement("canvas");
        video.style.display = "none";
        canvas.style.display = "none";
      
        // Trigger video load
        await new Promise<void>((resolve, reject) => {
          video.addEventListener("loadedmetadata", () => {
            video.width = video.videoWidth;
            video.height = video.videoHeight;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            // Seek the video to 25%
            video.currentTime = video.duration * 0.25;
          });
          video.addEventListener("seeked", () => resolve());
          video.src = videoUrl;
        });
      
        // Draw the thumbnailz
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const imageUrl = canvas.toDataURL("image/png");
        return imageUrl;
      }
      

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    
    
    ngOnDestroy(): void {
        // mise a jour de nombre message non lu 
        setTimeout(()=>{ 
            this.groupeService._request = {};
            this.groupeService._request.datas = [...this.groupes];
            this.groupeService.action = "updateMessageNonLu";
            this.groupeService.makeRequest();
        }, 1000);

        this.subscriptionModeration.unsubscribe();
        this.subscriptionGroupe.unsubscribe();
        this.subscriptionWebsocket.unsubscribe();

        this.websocketService.disconnect();
    }
   
}
