import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Academicien } from '../domain/academicien';
import { _Response } from '../domain/_response';
import { AcademicienService } from '../service/academicienService';

@Component({
  selector: 'app-carteMembre',
  templateUrl: './carte-membre.component.html',
  styleUrls: [ './generatepdf.component.css' ]
})
export class CarteMembreComponent implements OnInit, OnDestroy {
  id: number;
  academicien: Academicien;
  today: string;
  private sub: any;
  subscriptionAcademicien: Subscription;

  constructor(private route: ActivatedRoute, 
    private messageService: MessageService,
    private academicienService: AcademicienService){}


  ngOnInit(): void {
    this.id = JSON.parse(this.route.snapshot.paramMap.get('id'));
    this.academicien = {};
    var newDate = new Date();
    this.today = newDate.getDate()+'/'+(newDate.getMonth()+1)+'/'+newDate.getFullYear();

    this.academicienService._request = {};
    this.academicienService._request.data = {id: this.id};
    this.academicienService.action = "getByCriteria";
 
    this.academicienService.makeRequest();


    this.subscriptionAcademicien = this.academicienService.responseSubject
    .subscribe((response: _Response) => {
        if(response.hasError){
            this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
        }else {
            if(this.academicienService.action === "getByCriteria"){
                if(response.items){
                    this.academicien = {...response.items[0]};
                    this.academicien.carteMembreStandardRectoBg = 'https://sippecacademie.com/allFiles/carte-standard-recto-' + this.academicien.paysId + '.png';
                    this.academicien.carteMembreStandardVersoBg = 'https://sippecacademie.com/allFiles/carte-standard-verso-' + this.academicien.paysId + '.png';
                    this.academicien.carteMembreGoldenRectoBg = 'https://sippecacademie.com/allFiles/carte-golden-recto-' + this.academicien.paysId + '.png';
                    this.academicien.carteMembreGoldenVersoBg = 'https://sippecacademie.com/allFiles/carte-golden-verso-' + this.academicien.paysId + '.png';
                    return;
                }
            }
        }
    });
  }
  
  printPage(){
    let printContents, popupWin;
		printContents = document.getElementById('agrrement-section').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		if(popupWin){
			popupWin.document.open();
			popupWin.document.write(`
				<html>
					<head>
						<title>Carte membre</title>
            <body style="margin:0; padding:0" onload="window.print();window.close()">${printContents}</body>
          </head>
        </html>
      `)
      popupWin.document.close();
    }

  }
 
  printStandardPage(){
    let printContents, popupWin;
		printContents = document.getElementById('standard-section').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		if(popupWin){
			popupWin.document.open();
			popupWin.document.write(`
				<html>
					<head>
						<title>Carte membre</title>
            <body style="margin:0; padding:0" onload="window.print();window.close()">${printContents}</body>
          </head>
        </html>
      `)
      popupWin.document.close();
    }

  }




  ngOnDestroy(): void {
    this.subscriptionAcademicien.unsubscribe();
  }
}
