import { Subject } from 'rxjs';
import { _Response } from '../domain/_response';
import { environment } from 'src/environments/environment';
import { _Request } from '../domain/_request';
import { User } from '../domain/user';

export abstract class AbstractService<T> {
    _response: _Response;
    _request:_Request;
    totalRecord: number;

    entities: T[];
    entity: T;
   
    encryptedKey: string;

    abstract table: string;
    abstract libelle: string;
    action: string;

    responseSubject = new Subject<_Response>();
    entitiesSubject = new Subject<T[]>();

    constructor() { 
        this._request = {};
        this._request.data = {};
        
        this._response = {};
        this._response.hasError = false;
    }

    abstract getHttp();
    abstract getUtils();

    beforeRequest(){

    }

    afterRequest(){

    }

    emitEntities(){
      this.entitiesSubject.next(this.entities);
    }

    emitResponse(){
      this.responseSubject.next(this._response);
    }
  
    getEntities() {
        console.log('Requete : table ',this.table,' -- datas :',this._request);
        this.beforeRequest();
        this.getHttp().post(environment.url+"/"+this.table+"/getByCriteria", this._request)
          .subscribe(
            (response: any) => {
                const responseJson = JSON.parse(JSON.stringify(response));
                console.log('Serveur reponse : ',this.table, ' -- ', responseJson);
                this.afterRequest();
                this._response = responseJson;
                if(this._response.hasError){
                  console.log('Erreur : ',this._response.status.message);
                }else {
                  if(this._response.items){
                    this.entities = [...this._response.items];
                    this.totalRecord = this._response.count;
                  }
                  this.emitEntities();
                }
            },
            (error) => {
              console.log('Erreur ! : ',error);
            }
          );
    }
  
    makeRequest() {
      const user = JSON.parse(this.getFromLocalStorage("user")) as User ;
      if(user != null){
        this._request.user=user.id;
      }
      if(this.action != "connexion" && this.action != "passwordOublier" && !user){
        console.log("Aucun user connecté !");
        return;
      }
      this.beforeRequest();
      console.log('Requete : table ',this.table,' -- datas :',this._request,' --- service : ',this.action);
      this.getHttp().post(environment.url+"/"+this.table+"/"+this.action, this._request)
        .subscribe(
          (response: any) => {
              const responseJson = JSON.parse(JSON.stringify(response));
              this.afterRequest();
              console.log('Serveur reponse : ',this.table, ' -- ', responseJson);
              this._response = responseJson;
              this.emitResponse();
          },
          (error) => {
            console.log('Erreur ! : ', error);
          }
        );
    }

    getMethod(){
      this.getHttp().get(environment.url+"/"+this.table+"/"+this.action,{responseType: 'arraybuffer'} 
        ).subscribe(response => this.downLoadFile(response, "application/txt", this.action));
    }

    /**
    * Method is use to download file.
    * @param data - Array Buffer data
    * @param type - type of the document.
    */
    downLoadFile(data: any, type: string, fileName: string) {
      let blob = new Blob([data], { type: type});
      let url = window.URL.createObjectURL(blob);


      var link = document.createElement('a');
      link.href = url;

      console.log('--- objet recu ---', fileName);

      link.download = fileName.split("=")[1];
      // // link.target = "_blank";
      link.click();


      // let pwa = window.open(url);
      // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      //     alert( 'Le téléchargement à été bloqué par votre navigateur.');
      // }
    }

    // getMethod(){
    //   console.log('test telecharger fichier ', environment.url+"/"+this.table+"/"+this.action);

    //   this.getHttp().get(environment.url+"/"+this.table+"/"+this.action).subscribe({
    //     next: data => {
    //       console.log('test telecharger fichier ', data);
    //        // this.totalAngularPackages = data.total;
    //     },
    //     error: error => {
    //        // this.errorMessage = error.message;
    //         console.error('There was an error!', error);
    //     }
    // })
    // }

    storeToLocalStorage(key:string, value:string){
      const keyEncrypt = this.getUtils().encrypt(key);
      const valueEncrypt = this.getUtils().encrypt(value);
      localStorage.setItem(keyEncrypt,valueEncrypt);
    }

    getFromLocalStorage(key:string){
      const keyEncrypt = this.getUtils().encrypt(key);
      if(!localStorage.getItem(keyEncrypt)){
        return null;
      }
      const value = this.getUtils().decrypt(localStorage.getItem(keyEncrypt));
      return value;
    }

    deleteFromLocalStorage(key:string){
      const keyEncrypt = this.getUtils().encrypt(key);
      localStorage.removeItem(keyEncrypt); 
    }

}