import {Component, OnDestroy, OnInit} from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import { UserService } from './sippecacdemie/service/userService';
import { User } from './sippecacdemie/domain/user';

@Component({
    selector: 'app-inline-profile',
    template: `
        <div class="profile" [ngClass]="{'profile-expanded':active}">
            <a href="#" (click)="onClick($event)">
                <img style="border-radius:50%;" class="profile-image" src="assets/layout/images/avatar.jpeg" />
                <span class="profile-name">Kone Seriba</span>
                <i class="pi pi-fw pi-chevron-down"></i>
                <span class="profile-role">Admin</span>
            </a>
        </div>

        <ul id="profile-menu" class="layout-menu" [@menu]="active ? 'visible' : 'hidden'">
            <li role="menuitem">
                <a href="#" (click)="onEditerProfile()" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-user"></i>
                    <span>Profile</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Profile</div>
                </div>
            </li>
            <li role="menuitem">
                <a href="#" (click)="onChangerPassword()" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-user"></i>
                    <span>Profile</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Profile</div>
                </div>
            </li>
            <!--
                <li role="menuitem">
                    <a href="#" [attr.tabindex]="!active ? '-1' : null">
                        <i class="pi pi-fw pi-lock"></i>
                        <span>Privacy</span>
                    </a>
                    <div class="layout-menu-tooltip">
                        <div class="layout-menu-tooltip-arrow"></div>
                        <div class="layout-menu-tooltip-text">Privacy</div>
                    </div>
                </li>
                <li role="menuitem">
                    <a href="#" [attr.tabindex]="!active ? '-1' : null">
                        <i class="pi pi-cog"></i>
                        <span>Settings</span>
                    </a>
                    <div class="layout-menu-tooltip">
                        <div class="layout-menu-tooltip-arrow"></div>
                        <div class="layout-menu-tooltip-text">Settings</div>
                    </div>
                </li>
            -->
            <li role="menuitem">
                <a href="#" (click)="seDeconnecter()" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-sign-out"></i>
                    <span>Deconnection</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Logout</div>
                </div>
            </li>
        </ul>

        <p-dialog #dlgAct [(visible)]="acteurPasswordDialog" [style]="{width: '650px'}" header="Changer mot de passe" [modal]="true" styleClass="p-fluid">
            <ng-template  pTemplate="content">
                    
                <div class="p-field" >
                    <label for="password">Ancien mot de passe</label>
                    <input type="password" pInputText id="password" [(ngModel)]="acteur.password" />
                    <small class="p-invalid" *ngIf="submitted && !acteur.password">Champ Obligatoire.</small>
                </div>
                <div class="p-field">
                    <label for="password">nouveau mot de passe</label>
                    <input type="password" pInputText id="password" [(ngModel)]="acteur.password" />
                    <small class="p-invalid" *ngIf="submitted && !acteur.password">Champ Obligatoire.</small>
                </div>
                <div class="p-field">
                    <label for="password">Confirmer mot de passe</label>
                    <input type="password" pInputText id="password" [(ngModel)]="acteur.password" />
                    <small class="p-invalid" *ngIf="submitted && !acteur.password">Champ Obligatoire.</small>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" [disabled]="isDisabled"></button>
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="changerPassword()" [disabled]="isDisabled"></button>
            </ng-template>
        </p-dialog>

        <p-dialog #dlgAct [(visible)]="acteurDialog" [style]="{width: '650px'}" header="Editer profile" [modal]="true" styleClass="p-fluid">
            <ng-template  pTemplate="content">
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="nom">Nom & Prenoms</label>
                        <input type="text" pInputText id="nom" [(ngModel)]="acteur.nom" required  />
                        <small class="p-invalid" *ngIf="submitted && !acteur.nom">Champ Obligatoire.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="prenom">Email</label>
                        <input type="text" pInputText id="prenom" [(ngModel)]="acteur.email"  />
                        <small class="p-invalid" *ngIf="submitted && !acteur.email">Champ Obligatoire.</small>
                    </div>

                    <div class="p-field p-col-6">
                        <label for="telephone">Contact</label>
                        <input type="text" pInputText id="telephone" [(ngModel)]="acteur.telephone" />
                    </div>
                    <div class="p-field p-col-6" *ngIf="!acteur.id" >
                        <label for="password">Mot de passe</label>
                        <input type="password" pInputText id="password" [(ngModel)]="acteur.password" />
                        <small class="p-invalid" *ngIf="submitted && !acteur.password">Champ Obligatoire.</small>
                    </div>
                </div>
                
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" [disabled]="isDisabled"></button>
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="editerProfile()" [disabled]="isDisabled"></button>
            </ng-template>
        </p-dialog>
    `,
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppProfileComponent implements OnInit {

    active: boolean;
    acteurDialog: boolean;
    acteurPasswordDialog: boolean;
    acteur: User;

    constructor(private userService:UserService){}
  
    ngOnInit(): void {
        this.acteur = {};
    }
    
    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    onEditerProfile(){
        this.acteur = this.userService.getConnectUser();
        this.acteurPasswordDialog = true;
    }

    onChangerPassword(){
        this.acteur = this.userService.getConnectUser();
        this.acteurDialog = true;
    }

    seDeconnecter(){
        this.userService.deconnectUser();
    }

    changerPassword(){
    }

    editerProfile(){
    }

    hideDialog(){
        this.acteurDialog = false;
        this.acteurPasswordDialog = false;
    }

    // ngOnDestroy(): void {
    //     throw new Error('Method not implemented.');
    // }

}
