import {Component, OnInit} from '@angular/core';
import { AppComponent } from './app.component';
import { User } from './sippecacdemie/domain/user';
import { UserService } from './sippecacdemie/service/userService';

@Component({
    selector: 'app-menu',
    template: `
    <ul class="layout-menu layout-main-menu clearfix">
        <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
    </ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];
    acteur:User;

    constructor(public app: AppComponent){}
    
    
    ngOnInit() {
        this.acteur = {};
        // recuperer l'observable pour mettre a jour user
        this.app.updadedActeurSubject.subscribe((acteur: User) => {
            console.log("-- emission du user ---- : ", acteur);
            if(acteur.id){
                console.log("-- je suis dedans emission du user ---- : ", acteur);
                this.acteur = acteur;
               
                this.model = [
                    {
                        label: 'Vue Globale', icon: 'pi pi-home',
                        visibility: this.isGroupVueGlobale(),
                        items: [
                            {label: 'Dashboard', icon: 'pi pi-fw pi-desktop', routerLink: ['/dashboard'], visibility: this.acteur.roleIsDashbord},
                            {label: 'Statistiques', icon: 'pi pi-fw pi-chart-line', routerLink: ['/statistique'], visibility: this.acteur.roleIsStatistique},
                        ]
                    },
                    {
                        label: 'Gouassou', icon: 'pi pi-fw pi-wallet',
                        visibility: this.isGroupGouassou(),
                        items: [
                            {label: 'Académiciens', icon: 'pi pi-fw pi-users', routerLink: ['/academicien'], visibility: this.acteur.roleIsAcademicien},
                            {label: 'Operations', icon: 'pi pi-fw pi-money-bill', routerLink: ['/operation'], visibility: this.acteur.roleIsOperation},
                            {label: 'Produits', icon: 'pi pi-fw pi-table', routerLink: ['/produit'], visibility: this.acteur.roleIsProduit},
                            {label: 'Productions', icon: 'pi pi-fw pi-list', routerLink: ['/production'], visibility: this.acteur.roleIsProduction},
                        ]
                    },
                    {
                        label: 'Positions', icon: 'pi pi-fw pi-external-link',
                        visibility: this.isGroupPosition(),
                        items: [
                            {label: 'Positions', icon: 'pi pi-fw pi-external-link', routerLink: ['/position'], visibility: this.acteur.roleIsPosition},
                        ]
                    },
                    {
                        label: 'Tchat', icon: 'pi pi-fw pi-comment',
                        visibility: this.isGroupTchat(),
                        items: [
                            {label: 'Publications', icon: 'pi pi-fw pi-images', routerLink: ['/publication'], visibility: this.acteur.roleIsPublication},
                            {label: 'Moderation', icon: 'pi pi-fw pi-comments', routerLink: ['/moderation'], visibility: this.acteur.roleIsModeration},
                            {label: 'Assistance', icon: 'pi pi-fw pi-question-circle', routerLink: ['/assistance'], visibility: this.acteur.roleIsAssistance},
                        ]
                    },
                    {
                        label: 'Paramétrage', icon: 'pi pi-fw pi-sitemap', routerLink: ['/config'],
                        visibility: this.isGroupParametrage(),
                        items: [
                            {label: 'Applicatif', icon: 'pi pi-fw pi-cog', routerLink: ['/config/applicatif'], visibility: this.acteur.roleIsApplicatif},
                            {label: 'Utilisateur', icon: 'pi pi-fw pi-user', routerLink: ['/config/utilisateur'], visibility: this.acteur.roleIsUtilisateur},
                        ]
                    }
                ];
            }
        }) ;

        this.app.emitConnectedActeur();
  
    }

    isGroupVueGlobale(){
        if(!this.acteur.roleIsDashbord && !this.acteur.roleIsStatistique){
            return false;
        }
        return true;
    }

    isGroupGouassou(){
        if(!this.acteur.roleIsAcademicien && !this.acteur.roleIsOperation && !this.acteur.roleIsProduit && !this.acteur.roleIsProduction){
            return false;
        }
        return true;
    }

    isGroupPosition(){
        if(!this.acteur.roleIsPosition){
            return false;
        }
        return true;
    }

    isGroupTchat(){
        if(!this.acteur.roleIsPublication && !this.acteur.roleIsModeration && !this.acteur.roleIsAssistance ){
            return false;
        }
        return true;
    }

    isGroupParametrage(){
        if(!this.acteur.roleIsApplicatif && !this.acteur.roleIsUtilisateur){
            return false;
        }
        return true;
    }

    // acteur.isDashbord{
    //     return this.acteur.roleDashbord;
    // }

    // isStatistique(){
    //     return this.acteur.roleStatistique;
    // }

    // isAcademicien(){
    //     return this.acteur.roleAcademicien;
    // }

    // isOperation(){
    //     return this.acteur.roroleOperation;
    // }

    // isProduit(){
    //     return this.acteur.roleProduit;
    // }

    // isProduction(){
    //     return this.acteur.roleProduction;
    // }

    // isPosition(){
    //     return this.acteur.rolePosition;
    // }

    // isPublication(){
    //     return this.acteur.rolePublication;
    // }

    // isModeration(){
    //     return this.acteur.roleModeration;
    // }

    // isAssistance(){
    //     return this.acteur.roleAssistance;
    // }

    // isApplicatif(){
    //     return this.acteur.roleApplicatif;
    // }
    
    // isUtilisateur(){
    //     return this.acteur.roleUtilisateur;
    // }

}
