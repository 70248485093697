import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { Diffusion } from '../domain/diffusion';
import { DiffusionService } from '../service/diffusionService';
import { Groupe } from '../domain/academicien';
import { GroupeService } from '../service/groupeService';
import { Article } from '../domain/article';
import { ArticleService } from '../service/articleService';
import { Campagne } from '../domain/campagne';
import { CampagneService } from '../service/campagneService';
import { Commentaire } from '../domain/commentaire';


@Component({
    templateUrl: './diffusion.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class DiffusionComponent implements OnInit, OnDestroy {

    diffusionDialog: boolean;
    diffusions: Diffusion[];
    diffusion: Diffusion;

    campagneDialog: boolean;
    campagnes: Campagne[];
    campagne: Campagne;

    commentaires: Commentaire[];
    totalRecordCommentaire: number = 0;

    sendSmsDialog:boolean;
    smsList:boolean;

    isGroupe:string;

    groupeSelecteds: Groupe[];
    groupes: Groupe[];
   
    submitted: boolean;
    isDisabled: boolean;
    diffusionSearchField: string;

    totalRecord: number;

    articleDialog: boolean;
    showArticleDialog: boolean;
    articles: Article[];
    article: Article;
   
    articleSearchField: string;

    tabViewActiveIndex: number = 0;
    fieldFilter: any;
    text: string;

    subscriptionArticle: Subscription;
    subscriptionDiffusion: Subscription;
    subscriptionGroupe: Subscription;
    subscriptionCampagne: Subscription;

    constructor(private diffusionService: DiffusionService, 
                private groupeService: GroupeService,
                private campagneService: CampagneService,
                private messageService: MessageService,
                private articleService: ArticleService, 
                private utils: Utils,
                private confirmationService: ConfirmationService) {}

    ngOnInit() {
        this.commentaires = [];
        this.isGroupe = "true";

        this.articles = [];
        this.article = {};
        this.text = "reussi"

        this.diffusions = [];
        this.fieldFilter = {dateDebutArt:null, dateFinArt:null, dateDebutSms:null, dateFinSms:null};

        this.subscriptionGroupe = this.groupeService.entitiesSubject
        .subscribe((groupes: Groupe[]) => {
            if(groupes){
                this.groupes = groupes;
            }
                
        });

        this.groupeService.emitEntities();

        this.subscriptionDiffusion = this.diffusionService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'error', detail:response.status.message, life: 3000});
                }else {
                    if(this.diffusionService.action === "getByCriteria"){
                        // this.diffusion = {...response.items[0]}
                        if(response.items){
                            this.diffusions = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.diffusions = [];
                    }

                    if(this.diffusionService.action === "create"){
                        if(this.diffusions){
                            for(let prod of response.items){
                                this.diffusions.splice(0, 0, prod); // inserer en tete de liste
                            }
                        }
                        else {
                            this.diffusions = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du diffusion effectuée avec success', life: 3000});
                        this.diffusionDialog = false;
                        this.diffusion = {};
                    }

                    if(this.diffusionService.action === "update"){
                        let index = this.utils.findIndexById(this.diffusions, response.items[0].id).index;
                        this.diffusions[index] = response.items[0];  
                        this.messageService.add({severity:'success', summary: 'Mise à jour du diffusion effectuée avec success', life: 3000});
                    }

                    if(this.diffusionService.action === "delete"){// a revoir 
                        let index = this.utils.findIndexById(this.diffusions, this.diffusion.id).index;
                        this.diffusions.splice(index,1);
                        this.diffusion = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de diffusion effectuée avec success', life: 3000});
                    }
                }
            });

        this.subscriptionCampagne = this.campagneService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'error', detail:response.status.message, life: 3000});
                }else {
                    if(this.campagneService.action === "getByCriteria"){
                        if(response.items){
                            this.campagnes = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.campagnes = [];
                        this.totalRecord = 0;
                    }

                    if(this.campagneService.action === "create" && this.campagne.id){
                        this.messageService.add({severity:'success', summary: 'Campagne envoyé avec success', life: 3000});
                        this.campagneDialog = false;
                    }

                    if(this.campagneService.action === "create" && !this.campagne.id){
                        if(this.campagnes){
                            for(let sms of response.items){
                                this.campagnes.splice(0, 0, sms); // inserer en tete de liste
                            }
                        }
                        else {
                            this.campagnes = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du campagne effectuée avec success', life: 3000});
                        this.campagneDialog = false;
                        this.campagne = {};
                    }

                    if(this.campagneService.action === "update"){
                        let index = this.utils.findIndexById(this.campagnes, response.items[0].id).index;
                        this.campagnes[index] = response.items[0];  
                        this.messageService.add({severity:'success', summary: 'Mise à jour du campagne effectuée avec success', life: 3000});
                    }

                    if(this.campagneService.action === "delete"){// a revoir 
                        let index = this.utils.findIndexById(this.campagnes, this.campagne.id).index;
                        this.campagnes.splice(index,1);
                        this.campagne = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de diffusion effectuée avec success', life: 3000});
                    }
                }
            });

            this.subscriptionArticle = this.articleService.responseSubject
                .subscribe((response: _Response) => {
                    this.submitted = false;
                    this.isDisabled = false;
                    if(response.hasError){
                        this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
                    }else {
                        if(this.articleService.action === "getByCriteria"){
                            // this.article = {...response.items[0]}
                            if(response.items){
                                this.articles = [...response.items];
                                this.totalRecord = response.count;
                                return;
                            }
                            this.articles = [];
                            this.totalRecord = 0;
                        }

                        if(this.articleService.action === "create"){
                            if(this.articles){
                                for(let prod of response.items){
                                    this.articles.splice(0, 0, prod); // inserer en tete de liste
                                }
                            }
                            else {
                                this.articles = [...response.items];
                            }
                            this.messageService.add({severity:'success', summary: 'Creation du article effectuée avec success', life: 3000});
                            this.articleDialog = false;
                            this.article = {};
                        }

                        if(this.articleService.action === "update"){
                            let index = this.utils.findIndexById(this.articles, response.items[0].id).index;
                            this.articles[index] = response.items[0];  
                            this.messageService.add({severity:'success', summary: 'Mise à jour du article effectuée avec success', life: 3000});
                        }

                        if(this.articleService.action === "delete"){// a revoir 
                            let index = this.utils.findIndexById(this.articles, this.article.id).index;
                            this.articles.splice(index,1);
                            this.article = {};
                            this.messageService.add({severity:'success', summary: 'Suppression de article effectuée avec success', life: 3000});
                        }
                    }
                });

            this.subscriptionArticle = this.articleService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.articleService.action === "getCommentaire"){
                        // this.operation = {...response.items[0]}
                        if(response.items){
                            this.commentaires = [...response.items];
                            this.totalRecordCommentaire= response.count;
                            return;
                        }
                        this.totalRecordCommentaire = 0;
                        this.commentaires = [];
                    }
                }
            });
    }


    loadDiffusion(event){
        this.criteriaSearchDiffusion(event.rows, event.first/event.rows);
    }

    loadCampagne(event){
        this.criteriaSearchCampagne(event.rows, event.first/event.rows);
    }
       
    openShowArticleDialog(article: Article) {
        this.article = {...article};
        console.log("--- va init comment liste ------");
        this.totalRecordCommentaire = 0;
        this.commentaires = [];

        this.showArticleDialog = true;
    }

    handleChangeTabView(e){
        var index = e.index;
        if(index == 1){
            this.loadCommentaire();
        }
        console.log("--- index tab view --- ", index);
    }   

    loadCommentaire(){
        console.log("--- va load commentaire 0 ----");
        if(this.commentaires.length == 0){
            // this.isDisabled = true;
console.log("--- va load commentaire ----");
            this.articleService._request = {};
            this.articleService._request.data = {actualiteId: this.article.id};
    
            this.articleService.action = "getCommentaire";
            this.articleService.makeRequest();
        }
    }

    criteriaSearchDiffusion(size:string, index:number){
        this.diffusionService._request = {};
        this.diffusionService._request.data = {isSippec: true};
        this.diffusionService._request.size = size;
        this.diffusionService._request.index = index;
        this.diffusionService.action = "getByCriteria";
     
        this.diffusionService.makeRequest();
    }
    
    criteriaSearchCampagne(size:string, index:number){
        this.campagneService._request = {};
        this.campagneService._request.data = {};
        this.campagneService._request.size = size;
        this.campagneService._request.index = index;
        this.campagneService.action = "getByCriteria";
     
        this.campagneService.makeRequest();
    }

    hideDialog() {
        this.diffusionDialog = false;
        this.articleDialog = false;
        this.campagneDialog = false;
        this.sendSmsDialog = false;
        this.submitted = false;
        this.isDisabled = false;
    }
    
    openNewDiffusionDialog() {
        this.diffusion = {isVideo:false}
        this.submitted = false;
        this.isDisabled = false;
        this.diffusionDialog = true;
    }
   
    openNewSmsDialog() {
        this.campagne = {}
        this.submitted = false;
        this.isDisabled = false;
        this.campagneDialog = true;
    }
    
    openSendSmsDialog(campagne:Campagne) {
        this.campagne = {...campagne};
        this.submitted = false;
        this.isDisabled = false;
        this.sendSmsDialog = true;
    }


    openEditDiffusionDialog(diffusion: Diffusion) {
        this.diffusion = {...diffusion};
        this.diffusionDialog = true;
    }

    openShowDiffusionDialog(diffusion: Diffusion) {
        this.diffusion = {...diffusion};
        this.diffusionDialog = true;
    }

    deleteDiffusion(diffusion: Diffusion) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ce message?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            key: 'diffusion',
            accept: () => {
                this.diffusionService._request = {};
                this.diffusionService._request.datas = [diffusion];
                this.diffusion = diffusion;
                this.diffusionService.action = "delete";
                this.diffusionService.makeRequest();
            }
        });
    }

    saveDiffusion() {
        this.submitted = true;
        this.isDisabled = true;
// ajouter le fichet

        if (this.groupeSelecteds && this.diffusion.contenu) {
            if (this.diffusion.id) {
                this.diffusionService.action = "update";
            }
            else {
                this.diffusionService.action = "create";
            }

            // zjouter les groupe de reception
            this.diffusionService._request = {};
            this.diffusionService._request.datas = [this.diffusion];
            console.log('les groupes : ', this.diffusion);
            console.log('les groupes selected : ', this.groupeSelecteds);
          // this.diffusionService.makeRequest(); 
        } 
        else {
            this.isDisabled = false;
        }  
    } 

    saveSms(){
        this.submitted = true;
        this.isDisabled = true;
        if (this.campagne.titre && this.campagne.contenu) {
            if (this.campagne.id) {
                this.campagneService.action = "update";
            }
            else {
                this.campagneService.action = "create";
            }
            // ajouter les groupe de reception
            this.campagneService._request = {};
            this.campagneService._request.datas = [this.campagne];
            this.campagneService.makeRequest(); 
        } 
        else {
            this.isDisabled = false;
        }  
    }
   
    matriculeSelecties : any[];
    groupeSelecties : any [];

    sendSms(){
        this.submitted = true;
        this.isDisabled = true;

        this.campagneService.action = "create";
        this.campagneService._request = {};
        console.log('envoi de sms');
         if(this.isGroupe=="true" && this.groupeSelecties && this.groupeSelecties.length){
            this.campagne.datasGroupe = [];
            for(let grpId of this.groupeSelecties){
                var objG = {id:grpId};
                this.campagne.datasGroupe.push(objG);
            }           
            this.campagne.datasAcademicien = null;
            this.campagneService._request.datas = [this.campagne];  
            this.campagneService.makeRequest(); 
            return;
         }   
         if(this.isGroupe=="false" && this.matriculeSelecties && this.matriculeSelecties.length){
            this.campagne.datasAcademicien = [];
            for(let mat of this.matriculeSelecties){
                var objA = {matricule:mat.trim()};
                this.campagne.datasAcademicien.push(objA);
            }
            this.campagne.datasGroupe = null;
            this.campagneService._request.datas = [this.campagne];  
            this.campagneService.makeRequest(); 
            return;
         } 
        this.isDisabled = false;
    }

    deleteSms(campagne: Campagne) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ce sms?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            key: 'sms',
            accept: () => {
                this.campagneService._request = {};
                this.campagneService._request.datas = [campagne];
                this.campagne = campagne;
                this.campagneService.action = "delete";
                this.campagneService.makeRequest();
            }
        });
    }

    async myUploader(event, type) {
        const result = await this.toBase64(event.files[0]).catch(e => Error(e)); // convertir le fichier en string 
      
        if(this.tabViewActiveIndex == 0){
            this.article.fichierBase64 = result.toString().replace(/^data:(.*,)?/, "");    
            this.article.nomFichier = event.files[0].name;

            if(type==1){
                this.article.nomFichierVideo = null;
                this.article.nomFichierVisuel = event.files[0].name;
                this.article.isVideo = false;
            }
            if(type==2){
                this.article.nomFichierVisuel = null;
                this.article.nomFichierVideo = event.files[0].name;
                this.article.isVideo = true;
            }
        }
        // if(this.tabViewActiveIndex == 1){
        //     this.diffusion.nomFichier = event.files[0].name;
        //     this.diffusion.fichierBase64 = result.toString().replace(/^data:(.*,)?/, "");
            
        //     if(type=2){
        //         this.diffusion.isVideo = true;
        //     }
        // }

    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    renvoyerDiffusion(){

    }

    getByDate(){
        this.isDisabled = true;
        
        if(this.tabViewActiveIndex==0){
            var newDateDebut: string;
            var newDateFin: string;
            this.articles = [];
            if(this.fieldFilter.dateDebutArt && this.fieldFilter.dateFinArt){
                newDateDebut = new Date(this.fieldFilter.dateDebutArt).toLocaleDateString();
                newDateFin = new Date(this.fieldFilter.dateFinArt).toLocaleDateString();
            }
           
            this.articleService._request = {};
            this.articleService._request.data = {createdAt:newDateDebut, 
                                                    createdAtParam: {"operator":"[]",start:newDateDebut, end:newDateFin}};
            this.articleService._request.size = '5';
            this.articleService._request.index = 0;
            this.articleService.action = "getByCriteria";
            this.articleService.makeRequest();
        }

        if(this.tabViewActiveIndex==1){

            var newDateDebut: string;
            var newDateFin: string;
            this.campagnes = [];
            // campagne
            if(this.fieldFilter.dateDebutSms && this.fieldFilter.dateFinSms){
                newDateDebut = new Date(this.fieldFilter.dateDebutSms).toLocaleDateString();
                newDateFin = new Date(this.fieldFilter.dateFinSms).toLocaleDateString();
            }
    
            this.campagneService._request = {};
            this.campagneService._request.data = {dateDernierEnvoi:newDateDebut, 
                dateDernierEnvoiParam: {"operator":"[]",start:newDateDebut, end:newDateFin}};
    
            this.campagneService.action = "getByCriteria";
            this.campagneService.makeRequest();
        }
    }


    loadArticle(event){
        this.criteriaSearchArticle(event.rows, event.first/event.rows);
    }
       
    criteriaSearchArticle(size:string, index:number){
        this.articleService._request = {};
        this.articleService._request.data = {};
        this.articleService._request.size = size;
        this.articleService._request.index = index;
        this.articleService.action = "getByCriteria";
     
        this.articleService.makeRequest();
    }

    openNewArticleDialog() {
        this.article = {isVideo:false}
        this.submitted = false;
        this.isDisabled = false;
        this.articleDialog = true;
    }


    // openEditArticleDialog(article: Article) {
    //     this.article = {...article};
    //     this.articleDialog = true;
    // }

  
    deleteArticle(article: Article) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + article.titre+ '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            key: 'article',
            accept: () => {
                this.articleService._request = {};
                this.articleService._request.datas = [article];
                this.article = article;
                this.articleService.action = "delete";
                this.articleService.makeRequest();
            }
        });
    }
    
    saveArticle() {
        this.submitted = true;
        this.isDisabled = true;
        // ajouter le fichet

        if (this.article.titre && this.article.titre.trim()) {
            if(this.article.contenu && this.article.contenu.trim()){
                if(this.article.nomFichier){
                    if (this.article.id) {
                        this.articleService.action = "update";
                    }
                    else {
                        this.articleService.action = "create";
                    }
                    this.articleService._request = {};
                    this.articleService._request.datas = [this.article];
                    this.articleService.makeRequest(); 
                }
                else {
                    this.messageService.add({severity:'error', summary: 'Vous devez ajouter un media à l\'actualité !', life: 5000});
                    this.isDisabled = false;
                }
            }else {
                console.log('-------- not see article -----');
                this.isDisabled = false;
            }
        } 
        else {
            this.isDisabled = false;
        }  
    } 

    ngOnDestroy(): void {
        this.subscriptionArticle.unsubscribe();
        this.subscriptionDiffusion.unsubscribe();
        this.subscriptionGroupe.unsubscribe();
        this.subscriptionCampagne.unsubscribe();
    }
   
}
