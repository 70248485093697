export class StaticMessage {
    public static readonly SUCCESS_CREATE = 'Creation effectuée avec success';
    public static readonly SUCCESS_UPDATE = 'Mise à jour effectuée avec success';
    public static readonly SUCCESS_DELETE = 'Suppression effectuée avec success'; 

    public static readonly ERROR_INVALID_PASSWORD = 'Adresse mail ou mot de passe incorrect !'; 
    public static readonly ERROR_INVALID_JETON = 'Votre jeton n\'est pas valid !'; 

    public static readonly INFO_CONNECTION = 'Vous n\'avez pas de compte! Veuillez en créer un.'; 
    public static readonly INFO_CONNECTION_ADMIN = 'Veuillez vous rendre sur votre espace de connection !'; 

    public static readonly INVALID_FILE_SIZE = 'La Taille maximale acce)ptable est de 976.563 KB.'; 

    public static readonly DIALOG_TITLE_CREATE = 'Création'; 
    public static readonly DIALOG_TITLE_UPDATE = 'Mise à jour';    
    public static readonly DIALOG_TITLE_DETAIL = 'Detail'; 

    public static readonly ROLE_CODE_ADMIN = 'ADMIN';    
    public static readonly ROLE_CODE_USER = 'USER';

    public static readonly TYPE_ACADEMICIEN = 'TYPE_ACADEMICIEN';    
    public static readonly TYPE_OPERATION = 'TYPE_OPERATION';    
    public static readonly TYPE_OPERATION_SCAN = 'SCANNER';    
    public static readonly TYPE_OPERATION_TRANSACTION = 'RETIRER';    
    public static readonly TYPE_OPERATION_CADEAU = 'KDO';    
    public static readonly TYPE_DISCUSSION = 'TYPE_DISCUSSION';    
    
    public static readonly ETAT_PAS_DE_SCAN = 'PAS_DE_SCAN';    
    public static readonly ETAT_DEMANDE= 'DEMANDE';    
    public static readonly ETAT_SUCCES = 'SUCCES';    
    public static readonly ETAT_ECHEC = 'ECHEC';    
    public static readonly ETAT_EN_COURS = 'EN_COURS';    
    public static readonly ETAT_TERMINER = 'TERMINER';    
    public static readonly ETAT_A_CONTACTER_SCAN_ACTIVATION = 'A_CONTACTER_SCAN_ACTIVATION';    
    public static readonly ETAT_A_CONTACTER_WALLET_ORANGE = 'A_CONTACTER_WALLET_ORANGE';    
    public static readonly ETAT_BLOQUER = 'BLOQUER';    
    public static readonly ETAT_A_SUPPRIMER = 'A_SUPPRIMER';    
    public static readonly ETAT_DERNIERE_RELANCE = 'DERNIERE_RELANCE';    
    public static readonly ETAT_A_CONTACTER = 'A_CONTACTER';    
    public static readonly ETAT_INACTIF = 'INACTIF';    
    public static readonly ETAT_EN_VEILLE = 'EN_VEILLE';    
    public static readonly ETAT_SEMI_ACTIF= 'SEMI_ACTIF';    
    public static readonly ETAT_ACTIF = 'ACTIF';   

    public static readonly NBRE_SCAN = 'NBRE_SCAN';    
    public static readonly MONTANT_SCAN = 'MONTANT_SCAN';    
}
