import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { User } from './sippecacdemie/domain/user';
import { _Response } from './sippecacdemie/domain/_response';
import { UserService } from './sippecacdemie/service/userService';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    layoutMode = 'static';

    // darkMenu = false;
    darkMenu = true;
    profileMode = 'top'
    // profileMode = 'inline';

    ripple = true;
    acteur:User;
    
    updadedActeurSubject = new Subject<any>();

    inputStyle = 'outlined';
    subscriptionActeur: Subscription;

    constructor(private primengConfig: PrimeNGConfig, 
                private acteurService:UserService, 
                private router:Router,
                private messageService:MessageService) {

        // this.acteur = this.acteurService.getConnectUser();
        
        
    }

    ngOnInit() {
        console.log('--- dans app ---- ');

        this.acteur = this.acteurService.getConnectUser();
        if(this.acteur && this.acteur.id){
            this.acteurService.action = "getByCriteria";
            this.acteurService._request = {};
            this.acteurService._request.data = {id: this.acteurService.getConnectUser().id};
            this.acteurService.isReload = true;
            this.acteurService.makeRequest();    
        }
       
        this.subscriptionActeur = this.acteurService.responseSubject
            .subscribe((response: _Response) => {
                if(this.acteurService.action === "getByCriteria"){
                  
                    if(response.items){
                        var acteurTempon = JSON.parse(this.acteurService.getFromLocalStorage('user'));
                        console.log('Acteur tempon id : ',acteurTempon.id);
                        if(response.items){
                            for (let index = 0; index < response.items.length; index++) {
                                if(acteurTempon.id == response.items[index].id){
                                    this.acteurService.updateConnectUser(response.items[index]);
                                    this.acteur = response.items[index];
                                    this.emitConnectedActeur();
                                }
                            }
                        }
                    }
                    else{
                        console.log('--- user inexistant dans la bd ');
                        this.acteurService.deconnectUser();
                        return this.router.navigate(['/login']);
                    }
                }
                
            });

        this.primengConfig.ripple = true;
    }

    emitConnectedActeur(){
        this.updadedActeurSubject.next(this.acteur);
    }
}
