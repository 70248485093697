import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppProfileComponent} from './app.profile.component';
import {MenuService} from './app.menu.service';
import { AcademicienComponent } from './sippecacdemie/view/academicien.component';
import { OperationComponent } from './sippecacdemie/view/operation.component';
import { ProduitComponent } from './sippecacdemie/view/produit.component';
import { ProductionComponent } from './sippecacdemie/view/production.component';
import { PositionComponent } from './sippecacdemie/view/position.component';
import { AssistanceComponent } from './sippecacdemie/view/assistance.component';
import { ParamApplicatifComponent } from './sippecacdemie/view/param.applicatif.component';
import { ParamUtilisateurComponent } from './sippecacdemie/view/param.utilisateur.component';
import { DashboardComponent } from './sippecacdemie/view/dashboard.component';
import { StatistiqueComponent } from './sippecacdemie/view/statistique.component';
import { Utils } from './sippecacdemie/service/utils';
import { NatureService } from './sippecacdemie/service/natureService';
import { NiveauService } from './sippecacdemie/service/niveauService';
import { ContrainteService } from './sippecacdemie/service/contrainteService';
import { GroupeService } from './sippecacdemie/service/groupeService';
import { OperateurMobileService } from './sippecacdemie/service/operateurMobileService';
import { PaysService } from './sippecacdemie/service/PaysService';
import { CategorieService } from './sippecacdemie/service/categorieService';
import { ProduitService } from './sippecacdemie/service/produitService';
import { ProductionService } from './sippecacdemie/service/productionService';
import { AcademicienService } from './sippecacdemie/service/academicienService';
import { OperationService } from './sippecacdemie/service/operationService';
import { EtatService } from './sippecacdemie/service/etatService';
import { TypeOperationService } from './sippecacdemie/service/typeOperationService';
import { CommentaireService } from './sippecacdemie/service/commentaireService';
import { ArticleService } from './sippecacdemie/service/articleService';
import { DiffusionService } from './sippecacdemie/service/diffusionService';
import { PositionService } from './sippecacdemie/service/positionService';
import { ActeurService } from './sippecacdemie/service/acteurService';
import { RoleService } from './sippecacdemie/service/roleService';
import { DiffusionComponent } from './sippecacdemie/view/diffusion.component';
import { AssistanceService } from './sippecacdemie/service/assistanceService';
import { ModerationComponent } from './sippecacdemie/view/moderation.component';
import { DiscussionService } from './sippecacdemie/service/discussionService';
import { MessageService } from 'primeng/api';
import { AppNotfoundComponent } from './app.notfound.component';
import { AppAccessdeniedComponent } from './app.accessdenied.component';
import { AppErrorComponent } from './app.error.component';
import { AppLoginComponent } from './sippecacdemie/auth/app.login.component';
import { CampagneService } from './sippecacdemie/service/campagneService';
import { WebsocketService } from './sippecacdemie/service/websocketService';
import { Generatepdf2Component } from './sippecacdemie/view/generatepdf2.component';
import { CarteMembreComponent } from './sippecacdemie/view/carte-membre.component';


  
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        StatistiqueComponent,
        AcademicienComponent,
        OperationComponent,
        ProduitComponent,
        ProductionComponent,
        PositionComponent,
        ModerationComponent,
        AssistanceComponent,
        DiffusionComponent,
        ParamApplicatifComponent,
        ParamUtilisateurComponent,
        Generatepdf2Component,
        CarteMembreComponent,
        
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,

        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppProfileComponent,
        AppConfigComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService,
        NatureService,
        NiveauService,
        PaysService,
        GroupeService,
        OperateurMobileService,
        ContrainteService,
        CategorieService,
        ProduitService,
        ProductionService,
        AcademicienService,
        OperationService,
        EtatService,
        TypeOperationService,
        ContrainteService,
        CommentaireService,
        DiffusionService,
        ArticleService,
        PositionService,
        ActeurService,
        RoleService,
        AssistanceService,
        DiscussionService,
        MessageService,
        CampagneService,
        WebsocketService,
        Utils
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
