import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { Position } from '../domain/position';
import { PositionService } from '../service/positionService';
import { Cadidature } from '../domain/cadidature';

@Component({
    templateUrl: './position.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class PositionComponent implements OnInit, OnDestroy {

    positionDialog: boolean;
    positions: Position[];
    caditatRetenus: Cadidature[];
    caditatNonRetenus: Cadidature[];
    position: Position;
   
    submitted: boolean;
    isDisabled: boolean;
    positionSearchField: string;

    totalRecord: number;

    fieldFilter: any;
    text: string;
    subscriptionPosition: Subscription;

    constructor(private positionService: PositionService, 
                private messageService: MessageService,
                private utils: Utils,
                private confirmationService: ConfirmationService) {}

    ngOnInit() {
        this.positions = [];
        this.caditatRetenus = [];
        this.caditatNonRetenus = [];
        this.position = {};
        this.text = "reussi";
        this.fieldFilter = {dateDebut:null, dateFin:null};

        this.subscriptionPosition = this.positionService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.positionService.action === "getAllPosition"){
                        // this.position = {...response.items[0]}
                        if(response.items && !this.positionService._request.data.id){// filter
                            this.positions = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        if(this.positionService._request.data.id){ // single request
                            this.position = response.items[0];
                            this.positionDialog = true;
                            return;
                        }
                        this.positions = [];
                    }

                    if(this.positionService.action === "create"){
                        if(this.positions){
                            for(let prod of response.items){
                                this.positions.splice(0, 0, prod); // inserer en tete de liste
                            }
                        }
                        else {
                            this.positions = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du position effectuée avec success', life: 3000});
                        this.positionDialog = false;
                        this.position = {};
                    }

                    if(this.positionService.action === "update"){
                        let index = this.utils.findIndexById(this.positions, response.items[0].id).index;
                        this.positions[index] = response.items[0];  
                        this.messageService.add({severity:'success', summary: 'Mise à jour du position effectuée avec success', life: 3000});
                    }

                    if(this.positionService.action === "delete"){// a revoir 
                        let index = this.utils.findIndexById(this.positions, this.position.id).index;
                        this.positions.splice(index,1);
                        this.position = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de position effectuée avec success', life: 3000});
                    }
                }
            });
    }


    loadPosition(event){
        this.criteriaSearchPosition(event.rows, event.first/event.rows);
    }
       
    criteriaSearchPosition(size:string, index:number){
        this.positionService._request = {};
        this.positionService._request.data = {};
        this.positionService._request.size = size;
        this.positionService._request.index = index;
        this.positionService.action = "getAllPosition";
     
        this.positionService.makeRequest();
    }

    hideDialog() {
        this.positionDialog = false;
        this.submitted = false;
        this.isDisabled = false;
    }
    
    // beforeOpenPositionDialog(){
        
    //     if(this.natures.length == 1){
    //         if(this.position){
    //             this.position.typeId = this.natures[0].id;
    //         }
    //         else {
    //             this.position = {typeId: this.natures[0].id};
    //         }
    //     }
    // }

    openShowPositionDialog(position: Position){
        this.position = {...position};
        this.positionService._request = {};
        this.positionService._request.data = {id:this.position.id};
        this.positionService.action = "getAllPosition";

        this.positionService.makeRequest(); 
    }

    deletePosition(position: Position) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer cette position ?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.positionService._request = {};
                this.positionService._request.datas = [position];
                this.position = position;
                this.positionService.action = "delete";
                this.positionService.makeRequest();
            }
        });
    }

    getByDate(){
        let newDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
        let newDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();


        this.positionService._request = {};
        this.positionService._request.data = {dateDebut:newDateDebut, 
            dateDebutParam: {"operator":"[]",start:newDateDebut, end:newDateFin}};

        this.positionService.action = "getAllPosition";
        this.positionService.makeRequest();
    }


    ngOnDestroy(): void {
        this.subscriptionPosition.unsubscribe();
    }
   
}
