import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Academicien, Groupe, Niveau, OperateurMobile, Pays } from '../domain/academicien';
import { AcademicienService } from '../service/academicienService';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { Produit } from '../domain/produit';
import { ProduitService } from '../service/produitService';
import { Etat } from '../domain/etat';
import { Nature } from '../domain/Nature';
import { GroupeService } from '../service/groupeService';
import { EtatService } from '../service/etatService';
import { NiveauService } from '../service/niveauService';
import { NatureService } from '../service/natureService';
import { OperateurMobileService } from '../service/operateurMobileService';
import { OperationService } from '../service/operationService';
import { Operation } from '../domain/operation';
import { PaysService } from '../service/PaysService';
import { TypeOperation } from '../domain/typeOperation';
import { TypeOperationService } from '../service/typeOperationService';
import { Commentaire } from '../domain/commentaire';
import { CommentaireService } from '../service/commentaireService';
import { StaticMessage } from '../utilities/staticMessage';
import { Discussion } from '../domain/discussion';
import { DiscussionService } from '../service/discussionService';
import { Router } from '@angular/router';

@Component({
    templateUrl: './academicien.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class AcademicienComponent implements OnInit, OnDestroy {

    academicienDialog: boolean;
    academicienShowDialog: boolean;
    academiciens: Academicien[];
    academicien: Academicien;
   
    submitted: boolean;
    isDisabled: boolean;
    fieldFilter: any;
    stepsInfosAcademicien: MenuItem[];
    uploadedFiles: any[] = [];

    groupes: Groupe[];
    etats: Etat[];
    etatOperations: Etat[];
    niveaux: Niveau[];
    natures: Nature[];
    typeOperations: TypeOperation[];
    operateurMobiles: OperateurMobile[];
    produits: Produit[];
    paysList: Pays[];

    commentaires: Commentaire[];
    commentaire: Commentaire;
    
    discussions: Discussion[];
    discussion: Discussion;


    actions: MenuItem[]; 
    actionsLock: MenuItem[]; 

    operations: Operation[];
    tabViewActiveIndex: number = 0;
    fileUploadMode: string;
    totalRecord: number;

    subscriptionGroupe: Subscription;
    subscriptionEtat: Subscription;
    subscriptionNiveau: Subscription;
    subscriptionNature: Subscription;
    subscriptionAcademicien: Subscription;
    subscriptionOperation: Subscription;
    subscriptionOperateurMobile: Subscription;
    subscriptionPays: Subscription;
    subscriptionProduit: Subscription;
    subscriptionTypeOperation: Subscription;
    subscriptionCommentaire: Subscription;
    subscriptionDiscussion: Subscription;

    constructor(private academicienService: AcademicienService, 
                private groupeService: GroupeService, 
                private etatService: EtatService, 
                private niveauService: NiveauService, 
                private natureService: NatureService, 
                private operateurMobileService: OperateurMobileService, 
                private produitService: ProduitService, 
                private operationService: OperationService, 
                private paysService: PaysService, 
                private typeOperationService: TypeOperationService, 
                private commentaireService: CommentaireService, 
                private discussionService: DiscussionService, 
                private messageService: MessageService,
                private utils: Utils,
                private router: Router,
                private confirmationService: ConfirmationService) {      
    }

    ngOnInit() {
        console.log('portee de pays : ', this.paysService.paysSelectedId);
        this.actions = [
            {label: 'Visualiser', icon: 'pi pi-eye', command: () => {
                this.openShowAcademicienDialog(this.academicien);
            }},
            {label: 'Modifier', icon: 'pi pi-pencil', command: () => {
                this.openEditAcademicienDialog(this.academicien);
            } },
            {label: 'Bloquer', icon: 'pi pi-times',command: () => {
                this.bloquerAcademicien(this.academicien);
            }},
            {label: 'Supprimer', icon: 'pi pi-times',command: () => {
                this.deleteAcademicien(this.academicien);
            }},
            {separator: true},
            {label: 'Carte membre', icon: 'pi pi-card', command: () => {
                this.imprimerCarteMembre(this.academicien);
            }},
            {label: 'Diplome', icon: 'pi pi-bookmark', command: () => {
                this.imprimerDiplome(this.academicien);
            }}
        ];

        this.actionsLock  = [
            {label: 'Visualiser', icon: 'pi pi-eye', command: () => {
                this.openShowAcademicienDialog(this.academicien);
            }},
            {label: 'Modifier', icon: 'pi pi-pencil', command: () => {
                this.openEditAcademicienDialog(this.academicien);
            } },
            {label: 'Debloquer', icon: 'pi pi-times',command: () => {
                this.debloquerAcademicien(this.academicien);
            }},
            {label: 'Supprimer', icon: 'pi pi-times',command: () => {
                this.deleteAcademicien(this.academicien);
            }},
            {separator: true},
            {label: 'Carte membre', icon: 'pi pi-card', command: () => {
                this.imprimerCarteMembre(this.academicien);
            }},
            {label: 'Diplome', icon: 'pi pi-bookmark', command: () => {
                this.imprimerDiplome(this.academicien);
            }}
        ];

        this.academiciens = [];
        this.operations = [];
        this.etatOperations = [];
        this.groupes = [];
        this.etats = [];
        this.niveaux = [];
        this.natures = [];
        this.produits = [];
        this.paysList = [];
        this.operateurMobiles = [];
        this.typeOperations = [];
        this.isDisabled = true;
        this.totalRecord = 0;   
        // nature libelle pour afficher sur le panel
        this.fieldFilter = {groupeId:null,etatId:null,niveauId:null,natureLibelle:null, matricule:null, queryValue:null,
                            produitId:null, typeOperationCode:null, paysId:this.paysService.paysSelectedId};

        // pour recuperation des scans de l'academicien
        // this.produitService._request = {};
        // this.produitService._request.data = {};
        // this.produitService.getEntities(); 
      
    
        this.subscriptionPays = this.paysService.entitiesSubject
            .subscribe((paysList: Pays[]) => {
                if(paysList){
                    this.paysList = paysList;
                }
            });
       
        this.subscriptionProduit = this.produitService.entitiesSubject
            .subscribe((produits: Produit[]) => {
                if(produits){
                    this.produits = produits;
                    if(this.produits[0].libelle!='-'){
                        this.produits.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionGroupe = this.groupeService.entitiesSubject
            .subscribe((groupes: Groupe[]) => {
                if(groupes){
                    this.groupes = groupes;
                    if(this.groupes[0].libelle!='-'){
                        this.groupes.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
                    
            });

        this.subscriptionEtat = this.etatService.entitiesSubject
            .subscribe((etats: Etat[]) => {
                if(etats){
                    var etatTempon: Etat[] = [];
                    for(let etat of etats){
                        if(etat.type == StaticMessage.TYPE_ACADEMICIEN){
                            etatTempon.push( etat);
                        }
                        if(etat.type == StaticMessage.TYPE_OPERATION){
                            this.etatOperations.push( etat);
                        }
                    }
                    this.etats = etatTempon;
                    if(this.etats[0].libelle!='-'){
                        this.etats.splice(0, 0,  {id:null, libelle: '-'});
                    }
                    if(this.etatOperations[0].libelle!='-'){
                        this.etatOperations.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionNiveau = this.niveauService.entitiesSubject
            .subscribe((niveaux: Niveau[]) => {
                if(niveaux){
                    this.niveaux = niveaux;
                    if(this.niveaux[0].libelle!='-'){
                        this.niveaux.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionNature = this.natureService.entitiesSubject
            .subscribe((natures: Nature[]) => {
                if(natures){
                    var natureTempon: Nature[] = [];
                    for(let nature of natures){
                        if(nature.isAcademicien){
                            natureTempon.push(nature);
                        }
                    }
                    this.natures = natureTempon;
                    if(this.natures[0].libelle!='-'){
                        this.natures.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionOperateurMobile = this.operateurMobileService.entitiesSubject
            .subscribe((operateurMobiles: OperateurMobile[]) => {
                if(operateurMobiles)
                    this.operateurMobiles = operateurMobiles;
            });

        this.subscriptionTypeOperation = this.typeOperationService.entitiesSubject
            .subscribe((typeOperations: TypeOperation[]) => {
                if(typeOperations){
                    this.typeOperations = typeOperations;
                    if(this.typeOperations[0].libelle!='-'){
                        this.typeOperations.splice(0, 0,  {code:null, libelle: '-'});
                    }
                }
            });

        this.groupeService.emitEntities();
        this.paysService.emitEntities();
        this.produitService.emitEntities();
        this.etatService.emitEntities();
        this.niveauService.emitEntities();
        this.natureService.emitEntities();
        this.operateurMobileService.emitEntities();
        this.typeOperationService.emitEntities();

        this.subscriptionAcademicien = this.academicienService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.academicienService.action === "getByCriteria"){
                        if(response.items){
                            this.academiciens = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.academiciens = [];
                        this.totalRecord = 0;
                    }

                    if(this.academicienService.action === "create"){
                        if(this.academiciens){
                            this.academiciens.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.academiciens = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du academicien effectuée avec success', life: 3000});
                        this.academicienDialog = false;
                        this.academicien = {};
                    }

                    if(this.academicienService.action === "update"){
                        let index = this.utils.findIndexById(this.academiciens, response.items[0].id).index;
                        this.academiciens[index] = response.items[0];  
                        this.academicien = response.items[0];
                        this.messageService.add({severity:'success', summary: 'Mise à jour du academicien effectuée avec success', life: 3000});
                    }

                    if(this.academicienService.action === "delete"){
                        let index = this.utils.findIndexById(this.academiciens, this.academicien.id).index;
                        this.academiciens.splice(index,1);
                        this.academicien = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de academicien effectuée avec success', life: 3000});
                    }

                    if(this.academicienService.action === "generateExcelFile"){
                        this.academicienService.action = "downloadExcelFile?fileName=liste_des_academiciens.xlsx";
                        this.academicienService.getMethod();
                    }
                }
            });

        this.subscriptionOperation = this.operationService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.operationService.action === "getByCriteria"){
                        if(response.items){
                            this.operations = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.operations = [];
                        this.totalRecord = 0;
                    }
                }
            });

            this.subscriptionCommentaire = this.commentaireService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.commentaireService.action === "getByCriteria"){
                        if(response.items){
                            this.commentaires = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.commentaires = [];
                        this.totalRecord = 0;
                    }

                    if(this.commentaireService.action === "create"){
                        if(this.commentaires){
                            this.commentaires.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.commentaires = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du commentaire effectuée avec success', life: 3000});
                        this.commentaire = {};
                    }

                    if(this.commentaireService.action === "update"){
                        let index = this.utils.findIndexById(this.commentaires, response.items[0].id).index;
                        this.commentaires[index] = response.items[0];  
                        this.commentaire = response.items[0];
                        this.messageService.add({severity:'success', summary: 'Mise à jour du commentaire effectuée avec success', life: 3000});
                    }

                    if(this.commentaireService.action === "delete"){
                        let index = this.utils.findIndexById(this.commentaires, this.commentaire.id).index;
                        this.commentaires.splice(index,1);
                        this.commentaire = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de commentaire effectuée avec success', life: 3000});
                    }
                }
            });

            this.subscriptionDiscussion = this.discussionService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                }else {
                    if(this.discussionService.action === "getByCriteria"){
                        if(response.items){
                            this.discussions = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.discussions = [];
                        this.totalRecord = 0;
                    }
                }
            });
    }

    filtrerAcademicien(){
        console.log('filtrage de academicien : ', this.academicien);
    }

    loadAcademicien(event){
        this.criteriaSearchAcademicien(event.rows, event.first/event.rows,'getByCriteria');
    }
    
    loadOperation(event){
        //this.isDisabled = false;
        
        this.operationService._request = {};
        this.operationService._request.data = { etatId:this.fieldFilter.etatId,
                                                typeOperationCode:this.fieldFilter.typeOperationCode,
                                                academicienId: this.academicien.id,
                                                produitId: this.fieldFilter.produitId
                                                };
        
        this.operationService._request.size = event.rows;
        this.operationService._request.index = event.first/event.rows;
        this.operationService.action = "getByCriteria";
     
         this.operationService.makeRequest();

      //  this.criteriaSearchOperation(event.rows, event.first/event.rows);
    }

    loadCommentaire(event){
        this.criteriaSearchCommentaire(event.rows, event.first/event.rows);
    }
    
    loadDiscussion(event){
        this.criteriaSearchDiscussion(event.rows, event.first/event.rows);
    }
    

    criteriaSearchAcademicien(size:string, index:number, action:string){
        this.isDisabled = true;
        
        this.academicienService._request = {};
        this.academicienService._request.isAnd = false;

        this.academicienService._request.data = {groupeId:this.fieldFilter.groupeId,
                                                 etatId:this.fieldFilter.etatId,
                                                 niveauId:this.fieldFilter.niveauId,
                                                 typeId:this.fieldFilter.natureLibelle,
                                                 matricule:this.fieldFilter.queryValue,
                                                 paysId:this.paysService.paysSelectedId
                                                };
        this.academicienService._request.datas = [{groupeId:this.fieldFilter.groupeId,
            etatId:this.fieldFilter.etatId,
            niveauId:this.fieldFilter.niveauId,
            typeId:this.fieldFilter.natureLibelle,
            nom:this.fieldFilter.queryValue,
            paysId:this.paysService.paysSelectedId
            },
            {groupeId:this.fieldFilter.groupeId,
            etatId:this.fieldFilter.etatId,
            niveauId:this.fieldFilter.niveauId,
            typeId:this.fieldFilter.natureLibelle,
            prenoms:this.fieldFilter.queryValue,
            paysId:this.paysService.paysSelectedId
            },
            {groupeId:this.fieldFilter.groupeId,
            etatId:this.fieldFilter.etatId,
            niveauId:this.fieldFilter.niveauId,
            typeId:this.fieldFilter.natureLibelle,
            telephone:this.fieldFilter.queryValue,
            paysId:this.paysService.paysSelectedId
            }
        ];                  
        this.academicienService._request.size = size;
        this.academicienService._request.index = index;
        this.academicienService.action = action;
     
         this.academicienService.makeRequest();
    }

    criteriaSearchOperation(size:string, index:number){
        this.isDisabled = true;

        this.operationService._request = {};
        this.operationService._request.data = { etatId:this.fieldFilter.etatId,
                                                typeOperationCode:this.fieldFilter.typeOperationCode,
                                                academicienId: this.academicien.id,
                                                produitId: this.fieldFilter.produitId
                                                };
        
        this.operationService._request.size = size;
        this.operationService._request.index = index;
        this.operationService.action = "getByCriteria";
     
         this.operationService.makeRequest();
    }

    criteriaSearchCommentaire(size:string, index:number){
       // this.isDisabled = true;

        if(!this.commentaires || !this.commentaires.length){
            this.commentaireService._request = {};
            this.commentaireService._request.data = { };
            
            // this.operationService._request.size = size;
            // this.operationService._request.index = index;
            this.commentaireService.action = "getByCriteria";
         
             this.commentaireService.makeRequest();
        }
    }

    criteriaSearchDiscussion(size:string, index:number){
       // this.isDisabled = true;

        if(!this.discussions || !this.discussions.length){
            this.discussionService._request = {};
            this.discussionService._request.data = {academicienId:this.academicien.id};
            this.discussionService.action = "getByCriteria";
            this.discussionService.makeRequest();
        }
    }

    hideDialog() {
        this.groupes.splice(0, 0,  {id:null, libelle: '-'});
        this.natures.splice(0, 0,  {id:null, libelle: '-'});
        this.niveaux.splice(0, 0,  {id:null, libelle: '-'});

        // if(this.typeOperations[0].libelle=='-'){
        //     this.typeOperations.splice(0,1);
        // }

        this.academicienDialog = false;
        this.submitted = false;
        this.isDisabled = false;
    }
    
    actionList(academicien:Academicien){
        this.academicien = academicien;
    }

    handleChangeTabView(e){
        var index = e.index;
    }   

    openNewAcademicienDialog() {
        // suppression de premierer ligne sur les fiels etrangeres
        this.fileUploadMode = "basic";
        if(!this.paysList){
            this.paysService.emitEntities();
        }
        this.groupes.splice(0,1);
        this.natures.splice(0,1);
        this.niveaux.splice(0,1);

        this.academicien =  {groupeId:this.groupes[0].id,
                            etatId:this.etats[0].id,
                            niveauId:this.niveaux[0].id,
                            operateurMobileId:this.operateurMobiles[0].id,
                            typeId:this.natures[0].id,
                            paysId:this.paysService.paysSelected.id};

        this.submitted = false;
        this.isDisabled = false;
        this.academicienDialog = true;
    }

    openEditAcademicienDialog(academicien: Academicien) {
        this.fileUploadMode = "basic"
        if(!this.paysList){
            this.paysService.emitEntities();
        }
        this.groupes.splice(0,1);
        this.natures.splice(0,1);
        this.niveaux.splice(0,1);

        this.academicien = {...academicien};
        // control sur date naissance
        if(this.groupes.length == 1){
            this.academicien.groupeId = this.groupes[0].id;
        }
        if(this.etats.length == 1){
            this.academicien.etatId = this.etats[0].id;
        }
        if(this.niveaux.length == 1){
            this.academicien.niveauId = this.niveaux[0].id;
        }
        if(this.operateurMobiles.length == 1){
            this.academicien.operateurMobileId = this.operateurMobiles[0].id;
        }
        if(this.natures.length == 1){
            this.academicien.typeId = this.natures[0].id;
        }
        // convert string to date 
        // if(this.academicien.dateNaissance){
        //     this.academicien.dateNaissance = new Date(this.academicien.dateNaissance);
        //     // console.log('newDate date naissance : ', newDate);

        //     // console.log('newDate date naissance string : ', newDate.toLocaleDateString());

        //     // this.academicien.dateNaissance = newDate.toLocaleDateString();
        // }
        this.academicienDialog = true;
    }
    
    openShowAcademicienDialog(academicien: Academicien) {
        this.groupes.splice(0,1);
        this.natures.splice(0,1);
        this.niveaux.splice(0,1);

        this.academicien = {...academicien};
        // control sur date naissance
        this.commentaire = {academicienId: this.academicien.id};
        this.academicienShowDialog = true;
    }

    deleteAcademicien(academicien: Academicien) {
        this.confirmationService.confirm({
            key : 'acad',
            message: 'êtes vous sûr de vouloir supprimer ' + academicien.matricule + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.academicienService._request = {};
                this.academicienService._request.datas = [academicien];
                this.academicien = academicien;
                this.academicienService.action = "delete";
                this.academicienService.makeRequest();
            }
        });
    }


    initProduitDropdown(){
        this.fieldFilter.produitId = null;
    }
    nextStepSaving(){
        this.tabViewActiveIndex++;
        // var index = e.index;
        // if(index==0){ // actualisation pour prise en compte de modif sur cle etrangere
        //     // this.referentService._request.data = {isCreateByAdmin:true};
        //     // this.referentService.getEntities();
        // }
    }
 
    saveAcademicien() {
        this.submitted = true;
        this.isDisabled = true;
        if (this.academicien.telephone && this.academicien.nom ) {
            if(this.academicien.dateNaissance && this.academicien.dateNaissance instanceof Date){
                let newDate = new Date(this.academicien.dateNaissance);
                this.academicien.dateNaissance = newDate.toLocaleDateString();
            }
            if (this.academicien.id) {
                this.academicienService.action = "update";
            }
            else {
                this.academicienService.action = "create";
            }
            this.academicienService._request = {};
            this.academicienService._request.datas = [this.academicien];
    
           this.academicienService.makeRequest(); 
        } 
        else {
            this.isDisabled = false;
        }  
    }

    
    async myUploader(event, type) {
        const result = await this.toBase64(event.files[0]).catch(e => Error(e)); // convertir le fichier en string 

        console.log('Affichage du event : ',event.files[0]);
        if(type==1){ // identite
            this.academicien.nomFichierIdentite = event.files[0].name;
            this.academicien.fichierBase64Identite = result.toString().replace(/^data:(.*,)?/, "");
        }
        if(type==2){ // profile
           this.academicien.nomFichierPhoto = event.files[0].name;
           this.academicien.fichierBase64Photo = result.toString().replace(/^data:(.*,)?/, "");
        }
    //    this.saveAcademicien();      
    }

    deleteCommentaire(commentaire: Commentaire) {
        this.confirmationService.confirm({
            key : 'comment',
            message: 'êtes vous sûr de vouloir supprimer ' + commentaire.commentaire + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.commentaireService._request = {};
                this.commentaireService._request.datas = [commentaire];
                this.commentaire = commentaire;
                this.commentaireService.action = "delete";
                this.commentaireService.makeRequest();
            }
        });
    }

    bloquerAcademicien(academicien:Academicien){
        this.confirmationService.confirm({
            key : 'lock',
            message: 'êtes vous sûr de vouloir bloquer ' + academicien.nom + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                academicien.etatCode = StaticMessage.ETAT_BLOQUER;
                this.academicienService.action = "update";
                this.academicienService._request = {};
                this.academicienService._request.datas = [academicien];
                this.academicienService.makeRequest(); 
            }
        });
    }

    debloquerAcademicien(academicien:Academicien){
        this.confirmationService.confirm({
            key : 'unLock',
            message: 'êtes vous sûr de vouloir debloquer ' + academicien.nom + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                academicien.etatCode = StaticMessage.ETAT_ACTIF;
                this.academicienService.action = "update";
                this.academicienService._request = {};
                this.academicienService._request.datas = [academicien];
                this.academicienService.makeRequest();  
            }
        });
    }

    imprimerCarteMembre(academicien:Academicien){
        this.router.navigate(['/academicien/carte-membre', academicien.id]);
    }

    imprimerDiplome(academicien:Academicien){
        this.router.navigate(['/academicien/print', academicien.nom+' '+academicien.prenoms]);

    }

    saveCommenataire() {
        this.submitted = true;
        this.isDisabled = true;
        if (this.commentaire.commentaire ) {
            this.commentaire.academicienId = this.academicien.id;
            // this.commentaire.userId = // getConnect user
            this.commentaire.userId = 1;

            if (this.commentaire.id) {
                this.commentaireService.action = "update";
            }
            else {
                this.commentaireService.action = "create";
            }
            this.commentaireService._request = {};
            this.commentaireService._request.datas = [this.commentaire];
    
           this.commentaireService.makeRequest(); 
        } 
        else {
            this.messageService.add({severity:'error', summary: 'Erreur', detail:'Vous n\'avez pas ecris de commentaire !', life: 3000});
            this.isDisabled = false;
        }  
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });



    ngOnDestroy(): void {
        this.subscriptionGroupe.unsubscribe();
        this.subscriptionEtat.unsubscribe();
        this.subscriptionNiveau.unsubscribe();
        this.subscriptionNature.unsubscribe();
        this.subscriptionAcademicien.unsubscribe();
        this.subscriptionOperation.unsubscribe();
        this.subscriptionOperateurMobile.unsubscribe();
        this.subscriptionProduit.unsubscribe();
        this.subscriptionPays.unsubscribe();
        this.subscriptionTypeOperation.unsubscribe();
        this.subscriptionCommentaire.unsubscribe();
        this.subscriptionDiscussion.unsubscribe();
        this.groupes.splice(0,1);
        this.natures.splice(0,1);
        this.niveaux.splice(0,1);
        this.typeOperations.splice(0,1);
    }
   
}
