import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Diffusion } from '../domain/diffusion';

@Injectable({
    providedIn: 'root',
  })
export class WebsocketService {
    // webSocketEndPoint: string = 'http://localhost:8080/ws';
    topic: string = "/topic/public";
    stompClient: any;
    messageSubject = new Subject<Diffusion>();
    
    constructor(){
    } 

    connect () {        
        console.log("Initialize WebSocket Connection");
        let ws = new SockJS(environment.wsUrl);
        this.stompClient = Stomp.over(ws);
        const _this = this;

        _this.stompClient.connect({}, function (frame) {
            _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
                _this.onMessageReceived(sdkEvent);
            });
            // _this.stompClient.reconnect_delay = 2000;
        }, _this.errorCallBack);
    };

    disconnect() {
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        console.log("Disconnected");
        // mise a jour de la bf nbre message 
    }

    // on error, schedule a reconnection attempt
    errorCallBack =  (error) => {
        console.log("errorCallBack -> " + error)
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
            console.log("Disconnected");
        }
        setTimeout(() => {
            this.connect();
        }, 5000);
    }

 /**
  * Send message to sever via web socket
  * @param {*} message 
  */
    send(message) {
        console.log("calling logout api via web socket");
        this.stompClient.send("/app/hello", {}, JSON.stringify(message));
    }

    onMessageReceived(message) {
        console.log("Message Recieved from Server :: " + message);
        // EMIT LE MESSAGE SUBJECT...
        this.messageSubject.next(JSON.parse(message.body));
    }
}
