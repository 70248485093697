import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import { AcademicienComponent } from './sippecacdemie/view/academicien.component';
import { OperationComponent } from './sippecacdemie/view/operation.component';
import { ProduitComponent } from './sippecacdemie/view/produit.component';
import { ProductionComponent } from './sippecacdemie/view/production.component';
import { PositionComponent } from './sippecacdemie/view/position.component';
import { AssistanceComponent } from './sippecacdemie/view/assistance.component';
import { ParamApplicatifComponent } from './sippecacdemie/view/param.applicatif.component';
import { ParamUtilisateurComponent } from './sippecacdemie/view/param.utilisateur.component';
import { DashboardComponent } from './sippecacdemie/view/dashboard.component';
import { StatistiqueComponent } from './sippecacdemie/view/statistique.component';
import { DiffusionComponent } from './sippecacdemie/view/diffusion.component';
import { ModerationComponent } from './sippecacdemie/view/moderation.component';
import { AuthGuard } from './sippecacdemie/auth/auth.guard';
import { AppLoginComponent } from './sippecacdemie/auth/app.login.component';
import { AppMainComponent } from './app.main.component';
import { AppErrorComponent } from './app.error.component';
import { AppAccessdeniedComponent } from './app.accessdenied.component';
import { AppNotfoundComponent } from './app.notfound.component';
import { Generatepdf2Component } from './sippecacdemie/view/generatepdf2.component';
import { CarteMembreComponent } from './sippecacdemie/view/carte-membre.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
                    
                    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
                    {path: 'statistique', component: StatistiqueComponent, canActivate: [AuthGuard]},

                    {path: 'academicien', component: AcademicienComponent, canActivate: [AuthGuard]
                        // children:[
                        //     {path:'', redirectTo: 'identite', pathMatch: 'full'},
                        //     {path: 'identite', component: AcademicienIdentite},
                        //     // {path: 'formulaire', component: Formulaire},
                        // ]
                    },

                    {path: 'operation', component: OperationComponent, canActivate: [AuthGuard]},
                    {path: 'produit', component: ProduitComponent, canActivate: [AuthGuard]},
                    {path: 'production', component: ProductionComponent, canActivate: [AuthGuard]},
                    {path: 'position', component: PositionComponent, canActivate: [AuthGuard]},
                    
                    {path: 'moderation', component: ModerationComponent, canActivate: [AuthGuard]},
                    {path: 'publication', component: DiffusionComponent, canActivate: [AuthGuard]},
                    {path: 'assistance', component: AssistanceComponent, canActivate: [AuthGuard]},

                    {path: 'config/applicatif', component: ParamApplicatifComponent, canActivate: [AuthGuard]},
                    {path: 'config/utilisateur', component: ParamUtilisateurComponent, canActivate: [AuthGuard]},
                    {path: 'academicien/print/:username', component: Generatepdf2Component},
                    {path: 'academicien/carte-membre/:id', component: CarteMembreComponent}
                ]
            },
            
            {path: 'error', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
