import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppComponent } from 'src/app/app.component';
import { User } from '../domain/user';
import { UserService } from '../service/userService';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})


export class AppLoginComponent implements OnInit, OnDestroy {

  submitted: boolean;
  isDisabled: boolean;
  isBadFormat: boolean;
  acteur: User;
  isMotDePasseOublie: boolean;

  subscriptionUser: Subscription;

  constructor(public app:AppComponent,
              private userService:UserService, 
              private messageService:MessageService,
              private router:Router) { }

  
  ngOnInit(): void {
    this.acteur = {};
    this.submitted = false;

    this.subscriptionUser = this.userService
      .responseSubject.subscribe((response: any) => {
          this.isDisabled = false;

          if(response.hasError){
            if(response.status.code == "901"){
              this.messageService.add({key:'password',severity:'error', summary: 'Erreur', detail:"Adresse mail ou mot de passe incorrect !", life: 5000});
              return;
            }
            if(response.status.code == "902"){
              this.messageService.add({key:'noExistEmail',severity:'error', summary: 'Erreur', detail:"Desolé ! Vous n'êtes pas inscrit sur la plateforme !", life: 5000});
              return;
            }
          }else {
            if(this.userService.action === "connexion"){
              this.acteur = {...response.items[0]};
              // mettre user dans localstorage
              this.userService.updateConnectUser(this.acteur);
              this.userService.storeToLocalStorage('paysSelected',JSON.stringify(this.acteur.datasPays[0]));
              this.app.acteur = this.acteur;
            //  this.userService.updateConnectUser(this.acteur);
              this.goToAccessPage();
            }
            if(this.userService.action === "passwordOublier"){
              this.submitted = false;
              this.isDisabled = false;
              this.messageService.add({key:'success', severity:'success', summary: 'Mot de passe réinitialiser avec success !', life: 3000});
              this.isMotDePasseOublie = false;
            }
          }
          
      });
  }


  seConnecter(){
    this.submitted = true;

    if(!this.validateEmail(this.acteur.email)){
      this.isBadFormat = true;
      return;
    }
    this.isBadFormat = false;
    if(!this.acteur.password || !this.acteur.password.trim()){
      return;
    }

    this.isBadFormat = false;
    this.isDisabled = true;
  
    this.userService.action = "connexion";
    this.userService._request = {};
    this.userService._request.data = {...this.acteur};

    this.userService.makeRequest();    
  }

  motDePasseOubler(){
   this.acteur = {};
   this.isMotDePasseOublie = true;
  }

  retourConnection(){
    this.acteur = {};
    this.isMotDePasseOublie = false;
  }

  reinitialiserMotDePasse(){
    this.submitted = true;
    if(!this.acteur.email){
      return;
    }
    this.isDisabled = true;
    this.userService.action = "passwordOublier";
    this.userService._request = {};
    this.userService._request.data = {...this.acteur};

    this.userService.makeRequest();    
  }

  goToAccessPage(){
    console.log("redirection : ", this.acteur);

    if(this.acteur.roleIsDashbord){
      return this.router.navigate(['/dashboard']);
    }
    if(this.acteur.roleIsStatistique){
      return this.router.navigate(['/statistique']);
    }
    if(this.acteur.roleIsAcademicien){
      return this.router.navigate(['/academicien']);
    }
    if(this.acteur.roleIsOperation){
      return this.router.navigate(['/operation']);
    }
    if(this.acteur.roleIsProduit){
      return this.router.navigate(['/produit']);
    }
    if(this.acteur.roleIsProduction){
      return this.router.navigate(['/production']);
    }
    if(this.acteur.roleIsPosition){
      return this.router.navigate(['/position']);
    }
    if(this.acteur.roleIsModeration){
      return this.router.navigate(['/moderation']);
    }
    if(this.acteur.roleIsPublication){
      return this.router.navigate(['/publication']);
    }
    if(this.acteur.roleIsAssistance){
      return this.router.navigate(['/assistance']);
    }
    if(this.acteur.roleIsApplicatif){
      return this.router.navigate(['/config/applicatif']);
    }
    if(this.acteur.roleIsUtilisateur){
      return this.router.navigate(['/config/utilisateur']);
    }
  }


  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  ngOnDestroy(): void {
    this.subscriptionUser.unsubscribe();
  }

}
