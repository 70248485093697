import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import { Pays } from './sippecacdemie/domain/academicien';
import { User } from './sippecacdemie/domain/user';
import { _Response } from './sippecacdemie/domain/_response';
import { PaysService } from './sippecacdemie/service/PaysService';
import { UserService } from './sippecacdemie/service/userService';

@Component({
    selector: 'app-topbar',
    template: `
    
        <p-toast key="oldPasswordError"></p-toast>
        <p-toast key="updateSuccess"></p-toast>
        <p-toast key="updatePasswordSuccess"></p-toast>

        <div class="topbar clearfix">
            <div class="topbar-left">
                <a routerLink="/">
                    <img src="assets/layout/images/logo.png" class="topbar-logo" routerLink="/" />
                </a>
            </div>

            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="appMain.onMenuButtonClick($event)"
                   [ngClass]="{'menu-button-rotate': appMain.rotateMenuButton}">
                    <i class="pi pi-angle-left"></i>
               </a>

                <a id="topbar-menu-button" href="#" (click)="appMain.onTopbarMenuButtonClick($event)">
                    <i class="pi pi-bars"></i>
                </a>

                <ul class="topbar-items fadeInDown" [ngClass]="{'topbar-items-visible': appMain.topbarMenuActive}">
                    <li #profile class="profile-item" style="width:150px" *ngIf="app.profileMode==='top'|| appMain.isHorizontal()"
                        [ngClass]="{'active-top-menu':appMain.activeTopbarItem === profile}">

                        <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                            <img style="border-radius:50%;" class="profile-image"  src="assets/layout/images/avatar.jpeg" />
                            <span class="topbar-item-name">{{acteur.nom+' '}}</span>
                            <span class="topbar-item-role">{{acteur.roleLibelle}} 
                                <span style="margin:3px 0 0 8px; position:absolute"><i class="pi pi-fw pi-angle-down"></i></span>
                            </span> 
                        </a>
                        <ul class="layout-menu" [ngClass]="{'fadeInDown':!appMain.isMobile()}">
                            <li role="menuitem">
                                <a  (click)="onEditerProfile()" >
                                    <i class="pi pi-fw pi-user"></i>
                                    <span>Editer profile</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a  (click)="onChangerPassword()">
                                    <i class="pi pi-fw pi-user"></i>
                                    <span>Changer mot de passe</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a (click)="seDeconnecter()">
                                    <i class="pi pi-fw pi-lock"></i>
                                    <span>Se deconnecter</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #search class="search-item" [ngClass]="{'active-top-menu':appMain.activeTopbarItem === search}"
                        (click)="appMain.onTopbarItemClick($event,search)">
                        <div class="topbar-search">
                            <p-dropdown  id="pays" [options]="paysList" (onChange)="reloadPageDatas()" [(ngModel)]="pays" optionLabel="libelle" ></p-dropdown>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <p-dialog #dlgAct [(visible)]="acteurPasswordDialog" [style]="{width: '450px'}" header="Changer mot de passe" [modal]="true" styleClass="p-fluid">
            <ng-template  pTemplate="content">
                    
                <div class="p-field" >
                    <label for="password">Ancien mot de passe</label>
                    <input type="password" pInputText id="oldpassword" [(ngModel)]="oldPassword" />
                    <small class="p-invalid" *ngIf="submitted && !oldpassword">Champ Obligatoire.</small>
                </div>
                <div class="p-field">
                    <label for="password">nouveau mot de passe</label>
                    <input type="password" pInputText id="password" [(ngModel)]="acteur.password" />
                    <small class="p-invalid" *ngIf="submitted && !acteur.password">Champ Obligatoire.</small>
                </div>
                <div class="p-field">
                    <label for="password">Confirmer mot de passe</label>
                    <input type="password" pInputText id="newPassword" [(ngModel)]="newPassword" />
                    <small class="p-invalid" *ngIf="submitted && !newPassword">Champ Obligatoire.</small>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" [disabled]="isDisabled"></button>
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="changerPassword()" [disabled]="isDisabled"></button>
            </ng-template>
        </p-dialog>

        <p-dialog #dlgAct [(visible)]="acteurDialog" [style]="{width: '500px'}" header="Editer profile" [modal]="true" styleClass="p-fluid">
            <ng-template  pTemplate="content">
                <div class="p-field">
                    <label for="nom">Nom & Prenoms</label>
                    <input type="text" pInputText id="nom" [(ngModel)]="acteur.nom" required  />
                    <small class="p-invalid" *ngIf="submitted && !acteur.nom">Champ Obligatoire.</small>
                </div>
                <div class="p-field">
                    <label for="prenom">Email</label>
                    <input type="text" pInputText id="prenom" [(ngModel)]="acteur.email"  />
                    <small class="p-invalid" *ngIf="submitted && !acteur.email">Champ Obligatoire.</small>
                </div>
                <div class="p-field">
                    <label for="telephone">Contact</label>
                    <input type="text" pInputText id="telephone" [(ngModel)]="acteur.telephone" />
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" [disabled]="isDisabled"></button>
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="editerProfile()" [disabled]="isDisabled"></button>
            </ng-template>
        </p-dialog>
    `
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    paysList: Pays[];
    active: boolean;
    acteurDialog: boolean;
    acteurPasswordDialog: boolean;
    acteur: User;
    submitted: boolean;
    isDisabled: boolean;
    oldPassword: string;
    newPassword: string;
    pays:Pays;

    subscriptionUser: Subscription;

    constructor(public app: AppComponent, public appMain: AppMainComponent, 
                private paysService: PaysService, private userService:UserService,
                private router:Router,
                private messageService:MessageService) {}
    
    ngOnInit(): void {

        this.paysList = [];
        this.acteur = this.userService.getConnectUser();
        this.paysList = this.acteur.datasPays;

        console.log('---- datas pays de user ---', this.paysList);
        if(!this.paysService.getFromLocalStorage('paysSelected')){
            // this.paysService.paysSelected = this.paysList[this.paysList.length-1];
            this.paysService.paysSelected = this.paysList[0];
            this.paysService.storeToLocalStorage('paysSelected',JSON.stringify(this.paysService.paysSelected));
            this.paysService.paysSelectedId = this.paysService.paysSelected.id;
            this.paysService.paysSelectedCodeNumero = this.paysService.paysSelected.codeNumero;
        }
        else{
            this.paysService.paysSelected = JSON.parse(this.paysService.getFromLocalStorage('paysSelected'));
            this.paysService.paysSelectedId = this.paysService.paysSelected.id;
            this.paysService.paysSelectedCodeNumero = this.paysService.paysSelected.codeNumero;
        }
        this.pays = this.paysService.paysSelected;
        console.log('---- pays selected ---', this.pays);

        this.subscriptionUser = this.userService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;

                if(response.hasError){
                    this.messageService.add({key:'oldPasswordError', severity:'error', summary: 'Error', detail:"L'ancien mot de passe n'est pas valid !"});
                    return;
                }else {
                    if(this.userService.action === "update"){
                        this.acteur = response.items[0]; 
                        // get connect user 
                        var acteurTempon = JSON.parse(this.userService.getFromLocalStorage('user'));
                        console.log('Acteur tempon id : ',acteurTempon.id);
                        if(acteurTempon.id == this.acteur.id){
                            this.userService.updateConnectUser(this.acteur);
                        }
                        this.messageService.add({key:'updateSuccess', severity:'success', summary: 'Mise à jour de acteur effectuée avec success', life: 3000});
                    }
                    if(this.userService.action === "changerPassword"){
                        this.messageService.add({key:'updatePasswordSuccess',severity:'success', summary: 'Successful', detail: 'Votre mot de passe a été modifié avec success !', life: 3000});
                        this.acteurPasswordDialog = false;
                        this.oldPassword = "";
                        this.newPassword = "";
                    }
                }
            });
    }

    reloadPageDatas(){
        console.log('--- affichage du pays ---');
        this.paysService.storeToLocalStorage('paysSelected',JSON.stringify(this.pays));
        window.location.reload();
    }

    onEditerProfile(){
        this.acteur = this.userService.getConnectUser();
        this.acteurDialog = true;
    }

    onChangerPassword(){
        this.acteur = this.userService.getConnectUser();
        this.acteurPasswordDialog = true;
    }

    seDeconnecter(){
        this.userService.deconnectUser();
        return this.router.navigate(['/login']);
    }

    changerPassword(){
        this.isDisabled = true;
        this.submitted = true;

        if(!this.oldPassword || !this.newPassword || !this.acteur.password){
            this.isDisabled = false;
            return;
        }

        if(this.newPassword.trim() && this.acteur.password.trim()){
        
            if(this.newPassword != this.acteur.password){
                // les champs ne sont pas identiques
                this.isDisabled = false;
                this.messageService.add({key:'passwordError', severity:'error', summary: 'Error', detail:"Les champs ne sont pas identiques"});
                return;
            }
            this.submitted = false;
            // effectuer la modification
            this.userService.action = "changerPassword";
            this.userService._request = {};
            this.userService._request.data = {email:this.acteur.email, 
                                                password:this.oldPassword, 
                                                newPassword:this.newPassword};
            this.userService.makeRequest();  
        }

    }

    editerProfile(){
        this.submitted = true;
        this.isDisabled = true;
        if (this.acteur.nom && this.acteur.email) {
            this.userService.action = "update";
            this.userService._request = {};
            this.userService._request.datas = [this.acteur];
            this.userService.makeRequest();    
        }
        this.isDisabled = false;
    }

    hideDialog(){
        this.acteurDialog = false;
        this.acteurPasswordDialog = false;
    }

    ngOnDestroy(): void {
        this.subscriptionUser.unsubscribe();
    }

}
