import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { Utils } from './utils';
import { Discussion } from '../domain/discussion';
 

@Injectable()
export class DiscussionService extends AbstractService<Discussion>{
  table = "discussion";
  libelle ="Discussion";
  
  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
    // this.getEntities();
  }

  getHttp() {
    return this.http;
  }
  getUtils(){
    return this.utils;
  }

  beforeRequest(){
    // if(this._request.data){
    //   this._request.data.paysId = JSON.parse(this.getFromLocalStorage('paysSelected')).id;
    // }
    // if(this._request.datas){
    //   this._request.datas[0].paysId = JSON.parse(this.getFromLocalStorage('paysSelected')).id;
    // } 
  }
}