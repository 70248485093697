import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
    secretKey = "ENOK";

    findIndexById(tab: any[], id: number): any {
        let index = -1;
        let obj = {};

        for (let i = 0; i < tab.length; i++) {
            if (tab[i].id == id) {
                //index = i;
                obj = {index: i, value:tab[i]};
                break;
            }
        }
        return obj;
    }

    //private sanitizer:DomSanitizer
    // getSafeUrl(url) {
    //     return this.sanitizer.bypassSecurityTrustResourceUrl(url+"#toolbar=0");
    // }


    encrypt (text : string) {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars(this.secretKey).reduce((a, b) => a ^ b, code);
        
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        return text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
      };
      
    decrypt  (encoded : string) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(this.secretKey).reduce((a, b) => a ^ b, code);
  
    return encoded.match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16)).map(applySaltToChar).map((charCode) => String.fromCharCode(charCode)).join("");
    };
      
}