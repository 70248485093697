import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { Utils } from './utils';
import { Diffusion } from '../domain/diffusion';
import { PaysService } from './PaysService';


@Injectable()
export class DiffusionService extends AbstractService<Diffusion>{
  table = "message";
  libelle ="Message";
  
  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
  // this.getEntities();
  }

  getHttp() {
    return this.http;
  }
  getUtils(){
    return this.utils;
  }

  beforeRequest(){
    if(this._request.data){
      this._request.data.paysId = JSON.parse(this.getFromLocalStorage('paysSelected')).id;
    }
    
    if(this._request.datas){
      for (let index = 0; index < this._request.datas.length; index++) {
        this._request.datas[index].paysId = JSON.parse(this.getFromLocalStorage('paysSelected')).id;
      }
    } 
  }
}