import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { Role } from '../domain/role';
import { RoleService } from '../service/roleService';
import { Pays } from '../domain/academicien';
import { PaysService } from '../service/PaysService';
import { UserService } from '../service/userService';
import { User } from '../domain/user';

@Component({
    templateUrl: './param.utilisateur.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class ParamUtilisateurComponent implements OnInit, OnDestroy {

    acteurDialog: boolean;
    acteurs: User[];
    acteur: User;

    roleDialog: boolean;
    roles: Role[];
    role: Role;

    paysList: Pays[];
   
    roleSelected: boolean[] = [];

    submitted: boolean;
    isDisabled: boolean;

    paysSelected: Pays[];
    // isIdentification: boolean;
    // isIdentifScan: boolean;
    // isIdentifTransaction: boolean;
    // isIdentifMkg: boolean;
    // isStatistiqueGouassou: boolean;
    // isScan: boolean;
    // isTransaction: boolean;
    // isPublication: boolean;
    // isSms: boolean;
    // isModeration: boolean;
    // isStatistiqueMkg: boolean;
    // isAssistance: boolean;
    // isPosition: boolean;
    
    subscriptionActeur: Subscription;
    subscriptionRole: Subscription;
    subscriptionPays: Subscription;

    constructor(private acteurService: UserService, 
                private roleService: RoleService,
                private messageService: MessageService,
                private paysService: PaysService,
                private utils: Utils,
                private confirmationService: ConfirmationService) {}

    ngOnInit() {
       this.acteurs = [];
        this.roles = [];
        this.paysList = [];

        this.acteurService._request = {};
        this.acteurService._request.data = {};
        this.acteurService.action = "getByCriteria";
        this.acteurService.makeRequest();
        

        // this.subscriptionActeur = this.acteurService.entitiesSubject
        // .subscribe((acteurs: User[]) => {
        //     this.acteurs = acteurs;
        // });
        

        this.subscriptionRole = this.roleService.entitiesSubject
        .subscribe((roles: Role[]) => {
            this.roles = roles;
        });

        this.subscriptionPays = this.paysService.entitiesSubject
        .subscribe((paysList: Pays[]) => {
            this.paysList = paysList;
        });


      //  this.acteurService.emitEntities();
        this.roleService.emitEntities();
        this.paysService.emitEntities();


        this.subscriptionActeur = this.acteurService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.acteurService.action === "getByCriteria"){
                        if(response.items){
                            this.acteurs = [...response.items];
                            return;
                        }
                        this.acteurs = [];
                    }

                    if(this.acteurService.action === "create"){
                        if(this.acteurs){
                            this.acteurs.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.acteurs = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Création de acteur effectuée avec success', life: 3000});
                        this.acteurDialog = false;
                        this.acteur = {};
                    }
                    if(this.acteurService.action === "update"){
                        let index = this.utils.findIndexById(this.acteurs, response.items[0].id).index;
                        this.acteurs[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de acteur effectuée avec success', life: 3000});
                    }
                    if(this.acteurService.action === "delete"){
                        let index = this.utils.findIndexById(this.acteurs, this.acteur.id).index;
                        this.acteurs.splice(index,1);
                        this.acteur = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de acteur effectuée avec success', life: 3000});
                    }

                    // this.acteurService.entities = [...this.acteurs];
                }
            });
        
        this.subscriptionRole = this.roleService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;

                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.roleService.action === "create"){
                        if(this.roles){
                            this.roles.splice(0, 0, response.items[0]); // inserer en tete de liste
                        }
                        else {
                            this.roles = [response.items[0]];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation de role effectuée avec success', life: 3000});
                        this.roleDialog = false;
                        this.role = {};
                    }

                    if(this.roleService.action === "update"){
                        let index = this.utils.findIndexById(this.roles, response.items[0].id).index;
                        this.roles[index] = response.items[0];    
                        this.messageService.add({severity:'success', summary: 'Mise à jour de role effectuée avec success', life: 3000});
                    }
                    if(this.roleService.action === "delete"){
                        let index = this.utils.findIndexById(this.roles, this.role.id).index;
                        this.roles.splice(index,1);
                        this.role = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de role effectuée avec success', life: 3000});
                    }

                    this.roleService.entities = [...this.roles];
                }
            });
    }

    openNewActeurDialog() {
        this.acteur = {roleId: this.roles[0].id};
        this.submitted = false;
        this.acteurDialog = true;
    }

    openEditActeurDialog(acteur: User) {
        this.acteur = {...acteur};
        if(this.roles.length == 1){
            this.acteur.roleId = this.roles[0].id;
        }
        this.acteurDialog = true;
    }

    deleteActeur(acteur: User) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + acteur.nom + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.acteurService._request = {};
                this.acteurService._request.datas = [acteur];
                this.acteur = acteur;
                this.acteurService.action = "delete";
                this.acteurService.makeRequest();
            }
        });
    }

    hideDialog() {
        this.acteurDialog = false;
        this.roleDialog = false;
        this.submitted = false;
    }
    
    saveActeur() {
        this.submitted = true;
        this.isDisabled = true;

        if (this.acteur.nom && this.acteur.email && this.acteur.datasPays) {
            if (this.acteur.id) {
                this.acteurService.action = "update";
            }
            else {
                if(!this.acteur.password){
                    this.isDisabled = false;
                    return;
                }
                this.acteurService.action = "create";
            }
            this.acteurService._request = {};
            this.acteurService._request.datas = [this.acteur];

            this.acteurService.makeRequest();    
        }
        else{
            this.isDisabled = false;
        }
    }

    openNewRoleDialog() {
        this.role = {};
        this.submitted = false;
        this.roleDialog = true;
    }

    openEditRoleDialog(role: Role) {
        this.role = {...role};
        this.roleDialog = true;
    }

    deleteRole(role: Role) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + role.libelle + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.roleService._request = {};
                this.roleService._request.datas = [role];
                this.role = role;
                this.roleService.action = "delete";
                this.roleService.makeRequest();
            }
        });
    }

    saveRole() {
        this.submitted = true;
    
        if (this.role && this.role.libelle.trim()) {
            if (this.role.id) {
                this.roleService.action = "update";
            }
            else {
                this.roleService.action = "create";
            }
            this.roleService._request = {};
            this.roleService._request.datas = [this.role];

            this.roleService.makeRequest();    
        }
    }


    ngOnDestroy(): void {
        this.subscriptionActeur.unsubscribe();
        this.subscriptionRole.unsubscribe();
        this.subscriptionPays.unsubscribe();
    }
   
}
