import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Assistance } from '../domain/assistance';
import { AssistanceService } from '../service/assistanceService';
import { Subscription } from 'rxjs';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { DiscussionService } from '../service/discussionService';
import { Discussion } from '../domain/discussion';
import { StaticMessage } from '../utilities/staticMessage';
import { User } from '../domain/user';
import { UserService } from '../service/userService';
import { AcademicienService } from '../service/academicienService';
import { Academicien } from '../domain/academicien';
import { CommentaireService } from '../service/commentaireService';
import { EtatService } from '../service/etatService';
import { OperationService } from '../service/operationService';
import { ProduitService } from '../service/produitService';
import { TypeOperationService } from '../service/typeOperationService';
import { Etat } from '../domain/etat';
import { Operation } from '../domain/operation';
import { Produit } from '../domain/produit';
import { TypeOperation } from '../domain/typeOperation';
import { Commentaire } from '../domain/commentaire';

@Component({
    templateUrl: './assistance.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }
        .widget-chat .new-message .message-attachment {
            line-height: 0;
        }
        
        .p-inputtext:focus{
            box-shadow: none;
        }
        .p-inputtext {
            border: 0 none;
            font-size: 14px;
            width: 100%;
            background-color: transparent;
            outline: 0 none;
            padding-left: 8px;
            box-shadow: none;
            margin-top:8px
        }

        .theme-font-color{
            margin-top: 8px; 
            display: block;
        }
     
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})
export class AssistanceComponent implements OnInit, OnDestroy{

    @ViewChild('sp') msgScrollPanel; 

    assistances: Assistance[];
    assistanceNonLus: Assistance[];
    assistance: Assistance;
    academicien: Academicien;
    academicienId: number;

    discussion: Discussion;
    discussions: Discussion[];
    academicienShowDialog: boolean;

    acteur: User;
    
    etats: Etat[];
    etatOperations: Etat[];
    typeOperations: TypeOperation[];
    produits: Produit[];

    operations: Operation[];

    totalRecord: number;
    fieldFilter: any;

    commentaires: Commentaire[];


    submitted: boolean;
    isDisabled: boolean;
    myScrollVariable = 0;
    subscriptionAssistance: Subscription;
    subscriptionDiscussion: Subscription;
    subscriptionAcademicien: Subscription;
    
    subscriptionProduit: Subscription;
    subscriptionEtat: Subscription;
    subscriptionTypeOperation: Subscription;
    subscriptionOperation: Subscription;
    subscriptionCommentaire: Subscription;
        
    constructor(private assistanceService: AssistanceService, 
                private discussionService: DiscussionService,
                private messageService: MessageService,
                private acteurService: UserService,
                private etatService: EtatService, 
                private operationService: OperationService, 
                private typeOperationService: TypeOperationService, 
                private commentaireService: CommentaireService, 
                private produitService: ProduitService, 
                private academicienService: AcademicienService, 
                private utils: Utils,
                private confirmationService: ConfirmationService) {}
   
    ngOnInit() {
        this.operations = [];
        this.etatOperations = [];
        this.etats = [];
        this.produits = [];
        this.typeOperations = [];
        this.isDisabled = true;
        this.totalRecord = 0;   
        // nature libelle pour afficher sur le panel
        this.fieldFilter = {groupeId:null,etatId:null,niveauId:null,natureLibelle:null, matricule:null, queryValue:null,
                            produitId:null, typeOperationCode:null};

        // a changer avec role code changement etat
        this.acteur = this.acteurService.getConnectUser();
        
        this.assistance = {};
        this.discussionService._request = {};
        this.discussionService._request.data = {etatCode:StaticMessage.ETAT_EN_COURS, sippecId:this.acteur.id};
        this.discussionService.action = "getByCriteria";
        this.discussionService.makeRequest();
        this.assistanceNonLus = [];

        this.subscriptionProduit = this.produitService.entitiesSubject
            .subscribe((produits: Produit[]) => {
                if(produits){
                    this.produits = produits;
                    if(this.produits[0].libelle!='-'){
                        this.produits.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionEtat = this.etatService.entitiesSubject
            .subscribe((etats: Etat[]) => {
                if(etats){
                    var etatTempon: Etat[] = [];
                    for(let etat of etats){
                        if(etat.type == StaticMessage.TYPE_ACADEMICIEN){
                            etatTempon.push( etat);
                        }
                        if(etat.type == StaticMessage.TYPE_OPERATION){
                            this.etatOperations.push( etat);
                        }
                    }
                    this.etats = etatTempon;
                    if(this.etats[0].libelle!='-'){
                        this.etats.splice(0, 0,  {id:null, libelle: '-'});
                    }
                    if(this.etatOperations[0].libelle!='-'){
                        this.etatOperations.splice(0, 0,  {id:null, libelle: '-'});
                    }
                }
            });

        this.subscriptionTypeOperation = this.typeOperationService.entitiesSubject
            .subscribe((typeOperations: TypeOperation[]) => {
                if(typeOperations){
                    this.typeOperations = typeOperations;
                    if(this.typeOperations[0].libelle!='-'){
                        this.typeOperations.splice(0, 0,  {code:null, libelle: '-'});
                    }
                }
            });
        this.produitService.emitEntities();
        this.etatService.emitEntities();
        this.typeOperationService.emitEntities();
    
        this.subscriptionAssistance = this.assistanceService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
                }else {
                    if(this.assistanceService.action === "getByCriteriaCustom"){
                        this.assistanceNonLus = [];
                        this.assistances = [];


                        if(response.items){
                            for(let msg of response.items){
                                if(msg.isView){
                                    this.assistances.push(msg);
                                }
                                else if(msg.isAssistance){
                                    this.assistances.push(msg);
                                }
                                else {
                                    msg.isView = true;
                                    this.assistanceNonLus.push(msg);
                                }
                            }
                            // mise a jour de isView
                            if(this.assistanceNonLus[0]){
                                console.log('changer isView de : ',this.assistanceNonLus);
                                this.assistanceService._request = {};
                                this.assistanceService._request.datas = [...this.assistanceNonLus];
                        
                                this.assistanceService.action = "update";
                                this.assistanceService.makeRequest();

                                // nombre de message non lu sur discussion a 0
                                this.discussionService._request = {};
                                this.discussion.nbreNonLuSippec = 0;
                                this.discussionService._request.datas = [this.discussion];
                        
                                this.discussionService.action = "update";
                                this.discussionService.makeRequest();
                            }

                            if(!this.assistances[0] && this.assistanceNonLus[0]){
                                this.academicienId = this.assistanceNonLus[0].academicienId;
                            }
                            else {
                                this.academicienId = this.assistances[0].academicienId;
                            }
                            this.scrollToLastMessage("after-last-msg");
                            return;
                        }
                    }
                   
                    if(this.assistanceService.action === "getByCriteria"){
                        if(response.items){
                            this.assistances = [...response.items];
                            return;
                        }
                        this.assistances = [];
                    }

                    if(this.assistanceService.action === "create"){
                        if(this.assistances){
                            for(let prod of response.items){
                                this.assistances.push(prod); // inserer en fin de liste
                            }
                        }
                        else {
                            this.assistances = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Message envoyé avec success', life: 3000});
                        // this.assistanceDialog = false;
                        this.assistance = {};
                    }

                    // if(this.assistanceService.action === "update"){
                    //     let index = this.utils.findIndexById(this.assistances, response.items[0].id).index;
                    //     this.assistances[index] = response.items[0];  
                    //     this.messageService.add({severity:'success', summary: 'Mise à jour du assistance effectuée avec success', life: 3000});
                    // }

                    // if(this.assistanceService.action === "delete"){// a revoir 
                    //     let index = this.utils.findIndexById(this.assistances, this.assistance.id).index;
                    //     this.assistances.splice(index,1);
                    //     this.assistance = {};
                    //     this.messageService.add({severity:'success', summary: 'Suppression de assistance effectuée avec success', life: 3000});
                    // }
                }
            });
        
        this.subscriptionDiscussion = this.discussionService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
                }else {
                    if(this.discussionService.action === "getByCriteria"){
                        if(response.items){
                            this.discussions = [...response.items];
                            return;
                        }
                        this.discussions = [];
                    }

                    if(this.discussionService.action === "update"){
                        let index = this.utils.findIndexById(this.discussions, response.items[0].id).index;
                        if(response.items[0].etatCode == StaticMessage.ETAT_TERMINER){
                            this.discussions.splice(index,1);
                            this.discussion = {};
                            this.assistances = [];
                            return;
                        }
                        this.discussions[index] = response.items[0];  
                     //   this.messageService.add({severity:'success', summary: 'Mise à jour du discussion effectuée avec success', life: 3000});
                    }

                    if(this.discussionService.action === "delete"){// a revoir 
                        let index = this.utils.findIndexById(this.discussions, this.discussion.id).index;
                        this.discussions.splice(index,1);
                        this.discussion = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de discussion effectuée avec success', life: 3000});
                    }
                }
            });
  
            
       this.subscriptionAcademicien = this.academicienService.responseSubject
            .subscribe((response: _Response) => {
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({key:'serviceError', severity:'error', summary: 'Error', detail:response.status.message});
                }else {
                    if(this.academicienService.action === "getByCriteria"){
                        if(response.items){
                            this.academicien = {...response.items[0]};
                            this.academicienShowDialog = true;
                        }else {
                            this.messageService.add({severity:'error', summary: 'Cet academicien à été supprimer du système', life: 3000});
                        }
                    }
                }
            });

            this.subscriptionOperation = this.operationService.responseSubject
                .subscribe((response: _Response) => {
                    this.submitted = false;
                    this.isDisabled = false;
                    if(response.hasError){
                        this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                    }else {
                        if(this.operationService.action === "getByCriteria"){
                            if(response.items){
                                this.operations = [...response.items];
                                this.totalRecord = response.count;
                                return;
                            }
                            this.operations = [];
                            this.totalRecord = 0;
                        }
                    }
                });

            this.subscriptionCommentaire = this.commentaireService.responseSubject
                .subscribe((response: _Response) => {
                    this.submitted = false;
                    this.isDisabled = false;
                    if(response.hasError){
                        this.messageService.add({severity:'error', summary: 'Erreur', detail:response.status.message, life: 5000});
                    }else {
                        if(this.commentaireService.action === "getByCriteria"){
                            if(response.items){
                                this.commentaires = [...response.items];
                                this.totalRecord = response.count;
                                return;
                            }
                            this.commentaires = [];
                            this.totalRecord = 0;
                        }
                    }
                });

    }

    scrollToLastMessage(divId:string){
        var divPos = window.scrollX + document.querySelector("#"+divId).getBoundingClientRect().left // X
        setTimeout(()=>{ 
            this.msgScrollPanel.scrollTop(divPos);
        }, 1000);

    }

    showAcademcien(idAcademicien : number){
        this.academicienService._request = {};
        this.academicienService._request.data = {id:idAcademicien};
        this.academicienService.action = "getByCriteria";
        this.academicienService.makeRequest();
        this.isDisabled = true;
        this.academicien = {};
    }

    hideDialog() {
        this.academicienShowDialog = false;
        this.isDisabled = false;
    }
    
    getConversation(discussion: Discussion){
        this.isDisabled = true;
        this.discussion = discussion;
        this.assistanceService._request = {};
        this.assistanceService._request.data = {discussionId:discussion.id};

        this.assistanceService.action = "getByCriteriaCustom";
        this.assistanceService.makeRequest();
        
        this.assistance = {};
    }

    envoyerMessage(){
        if(!this.assistance.contenu){
            return;
        }
        this.isDisabled = true;
        this.assistance.isAssistance = true;     
        this.assistance.discussionId = this.discussion.id;     
        this.assistance.academicienId = this.academicienId;  
           
        this.assistanceService._request = {};
        this.assistanceService._request.datas = [this.assistance];

        this.assistanceService.action = "create";
        
        this.assistanceService.makeRequest();
    }
    
    clotureDiscussion(discussion: Discussion){
        this.discussionService._request = {};
        discussion.etatCode = StaticMessage.ETAT_TERMINER;
        this.discussionService._request.datas = [discussion];

        this.discussionService.action = "update";
        this.discussionService.makeRequest();

        this.isDisabled = true;
    }

    onRowSelect(event) {
        // this.discussion = this.selectedGroupes[0];
        this.getConversation(this.discussion);
        // this.messageService.add({severity:'info', summary:'Product Selected', detail: event.data.name});
    }

    onRowUnselect(event) {
        this.assistances = [];
      //  this.messageService.add({severity:'info', summary:'Product Unselected',  detail: event.data.name});
    }

    
    initProduitDropdown(){
        this.fieldFilter.produitId = null;
    }

    loadOperation(event){
        //this.isDisabled = false;
        
        this.operationService._request = {};
        this.operationService._request.data = { etatId:this.fieldFilter.etatId,
                                                typeOperationCode:this.fieldFilter.typeOperationCode,
                                                academicienId: this.academicien.id,
                                                produitId: this.fieldFilter.produitId
                                                };
        
        this.operationService._request.size = event.rows;
        this.operationService._request.index = event.first/event.rows;
        this.operationService.action = "getByCriteria";
     
         this.operationService.makeRequest();

      //  this.criteriaSearchOperation(event.rows, event.first/event.rows);
    }

    loadCommentaire(event){
        this.criteriaSearchCommentaire(event.rows, event.first/event.rows);
    }
    

    criteriaSearchOperation(size:string, index:number){
        this.isDisabled = true;

        this.operationService._request = {};
        this.operationService._request.data = { etatId:this.fieldFilter.etatId,
                                                typeOperationCode:this.fieldFilter.typeOperationCode,
                                                academicienId: this.academicien.id,
                                                produitId: this.fieldFilter.produitId
                                                };
        
        this.operationService._request.size = size;
        this.operationService._request.index = index;
        this.operationService.action = "getByCriteria";
     
         this.operationService.makeRequest();
    }

    criteriaSearchCommentaire(size:string, index:number){
       // this.isDisabled = true;

        if(!this.commentaires || !this.commentaires.length){
            this.commentaireService._request = {};
            this.commentaireService._request.data = { };
            
            // this.operationService._request.size = size;
            // this.operationService._request.index = index;
            this.commentaireService.action = "getByCriteria";
         
             this.commentaireService.makeRequest();
        }
    }

    ngOnDestroy(): void {
        this.subscriptionAssistance.unsubscribe();
        this.subscriptionDiscussion.unsubscribe();
        this.subscriptionEtat.unsubscribe();
        this.subscriptionAcademicien.unsubscribe();
        this.subscriptionOperation.unsubscribe();
        this.subscriptionProduit.unsubscribe();
        this.subscriptionTypeOperation.unsubscribe();
        this.subscriptionCommentaire.unsubscribe();
        this.produits.splice(0,1);
        this.typeOperations.splice(0,1);
        this.etats.splice(0,1);
    }
   
}
