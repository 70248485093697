import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { Utils } from './utils';
import { Production } from '../domain/production';


@Injectable()
export class ProductionService extends AbstractService<Production>{
  table = "groupeProductionQrcode";
  libelle ="Lot de production";
  
  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
  }

  getHttp() {
    return this.http;
  }
  getUtils(){
    return this.utils;
  }
}