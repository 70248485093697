import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Academicien } from '../domain/academicien';
import { AcademicienService } from '../service/academicienService';

@Component({
  selector: 'app-generatepdf2',
  templateUrl: './generatepdf2.component.html',
  styleUrls: [ './generatepdf.component.css' ]
})
export class Generatepdf2Component implements OnInit  {
  username: String;
  diplomeStandardBg: String
  diplomeGoldenBg: String
  paysId: number 
  private sub: any;

  constructor(private route: ActivatedRoute, private academicienService: AcademicienService){}

  ngOnInit(): void {
    this.username = this.route.snapshot.paramMap.get('username');
    this.paysId = JSON.parse(this.academicienService.getFromLocalStorage('paysSelected')).id;

    this.diplomeStandardBg = 'https://sippecacademie.com/allFiles/diplome-standard-' + this.paysId + '.png';
    this.diplomeGoldenBg = 'https://sippecacademie.com/allFiles/diplome-golden-' + this.paysId + '.png';
  }
  
 
  printPage(){
    let printContents, popupWin;
		printContents = document.getElementById('agrrement-section').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		if(popupWin){
			popupWin.document.open();
			popupWin.document.write(`
				<html>
					<head>
						<title>Diplôme</title>
            <body onload="window.print();window.close()">${printContents}</body>
          </head>
        </html>
      `)
      popupWin.document.close();
    }

  }
 
  printStandardPage(){
    let printContents, popupWin;
		printContents = document.getElementById('standard-section').innerHTML;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		if(popupWin){
			popupWin.document.open();
			popupWin.document.write(`
				<html>
					<head>
						<title>Diplôme</title>
            <body onload="window.print();window.close()">${printContents}</body>
          </head>
        </html>
      `)
      popupWin.document.close();
    }

  }
}
