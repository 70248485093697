import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { Utils } from './utils';
import { Etat } from '../domain/etat';


@Injectable()
export class EtatService extends AbstractService<Etat>{
  table = "etat";
  libelle ="Categorie";
  
  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
    this.getEntities();
  }

  getHttp() {
    return this.http;
  }
  getUtils(){
    return this.utils;
  }
}