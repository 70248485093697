import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _Response } from '../domain/_response';
import { _Request } from '../domain/_request';
import { AbstractService } from './abstractService';
import { User } from '../domain/user';
import { Subject } from 'rxjs';
import { Utils } from './utils';


@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractService<User>{

  table = "user";
  libelle = "utilisateur";

  private initPasswordForm = new Subject<any>();
  initPasswordForm$ = this.initPasswordForm.asObservable();
  emitPasswordForm: any;
  isReload: boolean;

  constructor(private http: HttpClient, 
    private utils:Utils) { 
    super();
    this._request.data = {};
    //this.getEntities();
  }

  getHttp() {
    return this.http;
  }

  getUtils() {
    return this.utils;
  }

  beforeRequest(){
    if(this._request.data && this.getFromLocalStorage('paysSelected')){
      this._request.data.paysId = JSON.parse(this.getFromLocalStorage('paysSelected')).id;
    }
    if(this._request.datas && this.getFromLocalStorage('paysSelected')){
      this._request.datas[0].paysId = JSON.parse(this.getFromLocalStorage('paysSelected')).id;
    } 
  }
  
  emitInitPasswordForm() {
    this.emitPasswordForm = "emission ok 1";
    this.initPasswordForm.next(this.emitPasswordForm);
  }

  getConnectUser():User{
    return JSON.parse(this.getFromLocalStorage('user'));
  }

  updateConnectUser(user:User){
    this.storeToLocalStorage('user', JSON.stringify(user));
  }

  removeConnectUser(){
    this.deleteFromLocalStorage('user');
  }

  
  refreshUser(){
    this.action = "getByCriteria";
    this._request = {};
    this._request.data = {id: this.getConnectUser().id};
    this.makeRequest();
  }
  
  deconnectUser(){
    this.removeConnectUser();
    this.deleteFromLocalStorage('paysSelected');
  }
}