import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Production } from '../domain/production';
import { ProductionService } from '../service/productionService';
import { _Response } from '../domain/_response';
import { Utils } from '../service/utils';
import { Produit } from '../domain/produit';
import { ProduitService } from '../service/produitService';
import { OperationService } from '../service/operationService';
import { Operation } from '../domain/operation';
import { Acteur } from '../domain/acteur';
import { ActeurService } from '../service/acteurService';
import { UserService } from '../service/userService';
import { User } from '../domain/user';

@Component({
    templateUrl: './production.component.html',
    styleUrls: ['./gouassou.scss'],
    styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }

        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
                text-align: center;
            }

            :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService]
})

export class ProductionComponent implements OnInit, OnDestroy {

    productionDialog: boolean;
    scannerDialog: boolean;
    productions: Production[];
    operations: Operation[];
    production: Production;
   
    submitted: boolean;
    isDisabled: boolean;
    productionSearchField: string;

    produits: Produit[];
    totalRecord: number;
    totalRecordOperation: number;

    fieldFilter: any;
    acteur : User;
    
    subscriptionProduit: Subscription;
    subscriptionProduction: Subscription;
    subscriptionOperation: Subscription;

    constructor(private productionService: ProductionService, 
                private produitService: ProduitService, 
                private operationService: OperationService, 
                private messageService: MessageService,
                private acteurService: UserService,
                private utils: Utils,
                private confirmationService: ConfirmationService) {}

    ngOnInit() {
       this.productions = [];
        this.produits = [];
        this.operations = [];
        
        this.fieldFilter = {dateDebut:null, dateFin:null};

        this.produitService._request = {};
        this.produitService._request.data = {};
        this.produitService.getEntities(); 
    
        this.acteur = this.acteurService.getConnectUser();


        this.subscriptionProduit = this.produitService.entitiesSubject
            .subscribe((produits: Produit[]) => {
                console.log('emission du subject ', produits);
                this.produits = produits;
            });

        this.subscriptionProduction = this.productionService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.productionService.action === "getByCriteria"){
                        // this.production = {...response.items[0]}
                        if(response.items){
                            this.productions = [...response.items];
                            this.totalRecord = response.count;
                            return;
                        }
                        this.totalRecord = 0;
                        this.productions = [];
                    }

                    if(this.productionService.action === "create"){
                        if(this.productions){
                            for(let prod of response.items){
                                this.productions.splice(0, 0, prod); // inserer en tete de liste
                            }
                        }
                        else {
                            this.productions = [...response.items];
                        }
                        this.messageService.add({severity:'success', summary: 'Creation du production effectuée avec success', life: 3000});
                        this.productionDialog = false;
                        this.production = {};
                    }

                    if(this.productionService.action === "update"){
                        let index = this.utils.findIndexById(this.productions, response.items[0].id).index;
                        this.productions[index] = response.items[0];  
                        this.messageService.add({severity:'success', summary: 'Mise à jour du production effectuée avec success', life: 3000});
                    }

                    if(this.productionService.action === "delete"){// a revoir 
                        let index = this.utils.findIndexById(this.productions, this.production.id).index;
                        this.productions.splice(index,1);
                        this.production = {};
                        this.messageService.add({severity:'success', summary: 'Suppression de production effectuée avec success', life: 3000});
                    }
                }
            });

        this.subscriptionOperation = this.operationService.responseSubject
            .subscribe((response: _Response) => {
                this.submitted = false;
                this.isDisabled = false;
                if(response.hasError){
                    this.messageService.add({severity:'error', summary: response.status.message, life: 3000});
                }else {
                    if(this.operationService.action === "getByCriteria"){
                        // this.operation = {...response.items[0]}
                        if(response.items){
                            this.operations = [...response.items];
                            this.totalRecordOperation = response.count;
                            return;
                        }
                        this.totalRecordOperation = 0;
                        this.operations = [];
                    }
                }
            });
    }

    filtrerProduction(){
        console.log('filtrage de production : ', this.production);
    }

    loadProduction(event){
        this.criteriaSearchProduction(event.rows, event.first/event.rows);
    }
       
    criteriaSearchProduction(size:string, index:number){
    
        this.productionService._request = {};
        this.productionService._request.data = {};

        this.productionService._request.size = size;
        this.productionService._request.index = index;
        
        if(this.fieldFilter.dateDebut && this.fieldFilter.dateFin){
            let newDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
            let newDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();
    
            this.productionService._request.data = {createdAt:newDateDebut, 
                createdAtParam: {"operator":"[]",start:newDateDebut, end:newDateFin}};    
        }

        this.productionService.action = "getByCriteria";
     
        this.productionService.makeRequest();

       // pour test  id?: number;

    }

    hideDialog() {
        this.productionDialog = false;
        this.scannerDialog = false;

        this.submitted = false;
        this.isDisabled = false;
    }
    
    // beforeOpenProductionDialog(){
        
    //     if(this.natures.length == 1){
    //         if(this.production){
    //             this.production.typeId = this.natures[0].id;
    //         }
    //         else {
    //             this.production = {typeId: this.natures[0].id};
    //         }
    //     }
    // }

    openNewProductionDialog() {
        this.production = {produitId: this.produits[0].id, nbreQrcode:256};
        this.submitted = false;
        this.isDisabled = false;
        this.productionDialog = true;
    }

    openEditProductionDialog(production: Production) {
        this.production = {...production};
        if(this.produits.length == 1){
            this.production.produitId = this.produits[0].id;
        }
        this.productionDialog = true;
    }

    deleteProduction(production: Production) {
        this.confirmationService.confirm({
            message: 'êtes vous sûr de vouloir supprimer ' + production.of + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.productionService._request = {};
                this.productionService._request.datas = [production];
                this.production = production;
                this.productionService.action = "delete";
                this.productionService.makeRequest();
            }
        });
    }
    
    telechargerProduction(production:Production){
        // var blob = new Blob([production.uriFile], {type:'application/png'});
        //var downloadURL = window.URL.createObjectURL(production.uriFile);

        // this.productionService._request = {};
        // this.productionService._request.datas = [production];
        // this.production = production;
        console.log('la production : ', production);
        this.productionService.action = "downloadFile?fileName="+production.uriFile;
        this.productionService.getMethod();

        // /groupeProductionQrcode/downloadFile
        // var link = document.createElement('a');
        // link.href = production.uriFile;
        // link.download = 'production_'+production.of.toString()+'.txt';
        // // link.target = "_blank";
        // link.click();

        // console.log('ceci est un test : ', link);
    }

    saveProduction() {
        this.submitted = true;
        this.isDisabled = true;
        if (this.production.ofDebut && this.production.ofFin && this.production.nbreQrcode) {
            if(this.production.ofDebut > this.production.ofFin){
                this.isDisabled = false;
                this.messageService.add({severity:'error', summary: 'OF de debut est superieur à OF de fin !', life: 3000});
                return;
            }
            if (this.production.id) {
                this.productionService.action = "update";
            }
            else {
                this.productionService.action = "create";
            }
            this.productionService._request = {};
            this.productionService._request.datas = [this.production];
    
           this.productionService.makeRequest(); 
        } 
        else {
            this.isDisabled = false;
        }  
    }

    viewScanner(production){
        this.isDisabled = true;
        this.totalRecordOperation = 0;
        this.operations = [];

        this.operationService._request = {};
        this.operationService._request.data = {groupeProductionQrcodeId: production.id};

        this.operationService.action = "getByCriteria";
        this.operationService.makeRequest();
        this.scannerDialog = true;
    }

    getByDate(){
        this.isDisabled = true;
        let newDateDebut = new Date(this.fieldFilter.dateDebut).toLocaleDateString();
        let newDateFin = new Date(this.fieldFilter.dateFin).toLocaleDateString();


        this.productionService._request = {};
        this.productionService._request.data = {createdAt:newDateDebut, 
                                                createdAtParam: {"operator":"[]",start:newDateDebut, end:newDateFin}};

        this.productionService._request.size = '5';
        this.productionService._request.index = 0;

        this.productionService.action = "getByCriteria";
        this.productionService.makeRequest();
    }


    ngOnDestroy(): void {
        this.subscriptionProduit.unsubscribe();
        this.subscriptionProduction.unsubscribe();
    }
   
}
