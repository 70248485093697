// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  url: "https://sippecacademie.com:9000",
  // url: "http://92.222.217.81:9000"
  //  url: "http://localhost:8080",
    wsUrl: "https://sippecacademie.com:9000/ws"
};
  